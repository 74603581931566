import { useEffect } from "react"
import Storage from "../../utils/storage";
import { appConfig } from "../../constants/config";
import { useAppDispatch } from "../../state/store";
import { selectAuthToken, setAuthToken } from "../../state/slices/auth";
import { fetchWorkspaceInfo } from "../../state/thunks/fetchWorkspaceData";
import { getUserInfo } from "state/thunks/auth/getUserInfo";
import { useSelector } from "react-redux";

export const AuthController = () => {
    const token = useSelector(selectAuthToken);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const token = Storage.get(appConfig.storageKey);
        if (token) {
            dispatch(setAuthToken(token));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token) {
            dispatch(getUserInfo({}));
            dispatch(fetchWorkspaceInfo({ id: '1' }));    
        }
    }, [token]);

    return null;
}