import styled from "@emotion/styled/macro";
import { Button } from "../button/button";

export const DropdownContainer = styled.div((props) => ({
    padding: 8,
    backgroundColor: props.theme.colors.background.primary,
}));

export const StyledButton = styled(Button)((props) => ({
    padding: 20,
    borderRadius: 10,
    backdropFilter: 'blur(10px)',
    opacity: 1,
}))

export const Wrapper = styled.div(() => ({
    display: 'flex',
}));