import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { WorkspaceEntity } from "../../../types/app";

export interface DeleteFileResponse {
    items: WorkspaceEntity[];
}

interface DeleteFileArgs {
    fileId: string;
}

export const action = 'files/delete'

export const deleteFile = createAsyncThunk(
    action,
    async (args: DeleteFileArgs, thunkApi) => {
        const response = await callApi<DeleteFileResponse>(
            rpcMethods.files.delete,
            { file_id: args.fileId },
        );
        return response;
    },
)