import { CapsuleSectionContainer, ActionItem, CapsuleIcon, CapsuleItemText, CapsuleList, CapsuleListHeader, CapsuleListItem, DropdownActions } from '../styles';
import { IconCapsule } from 'components/icons/capsule';
import { useTranslation } from 'react-i18next';
import { LABELS } from '../keys';
import { IconDots } from 'components/icons/dots';
import { DropdownButton } from 'components/buttons/dropdownButton/dropdownButton';
import { LoadingIndicator } from 'components/loading/indicator';
import { CapsuleItem, CapsuleStatus } from 'types/app';

interface CapsuleSectionProps {
    capsules: CapsuleItem[];
    enabledCapsules: string[];
    isFetching: boolean;
    removeCapsule: (capsule: CapsuleItem) => void;
    openCapsuleModal: (capsule: CapsuleItem) => void;
    onCapsuleStateChange: (capsule: CapsuleItem) => () => void;
}

export const CapsuleSection = ({
    capsules,
    isFetching,
    removeCapsule,
    openCapsuleModal,
    enabledCapsules,
    onCapsuleStateChange,
}: CapsuleSectionProps) => {
    const { t } = useTranslation();

    if (isFetching) {
        return (
            <CapsuleSectionContainer>
                <CapsuleListHeader>
                    {t(LABELS.LIST_TITLE)}
                </CapsuleListHeader>
                <LoadingIndicator size={15} />
            </CapsuleSectionContainer>
        );
    }

    return (
        <CapsuleSectionContainer>
            <CapsuleListHeader>
                {t(LABELS.LIST_TITLE)}
                {/* <Button flat>
                    {t(LABELS.VIEW_BUTTON_LABEL)}
                </Button> */}
            </CapsuleListHeader>
            { isFetching && <LoadingIndicator /> }
            <CapsuleList>
                {capsules.map(capsule => (
                    <CapsuleListItem key={capsule.id}>
                        <CapsuleIcon
                            icon
                            active={enabledCapsules.includes(capsule.id)}
                            ready={capsule.status === CapsuleStatus.Ready}
                            onClick={onCapsuleStateChange(capsule)}
                        >
                            <IconCapsule width={12} height={12} />
                        </CapsuleIcon>
                        <CapsuleItemText>
                            {capsule.name}
                        </CapsuleItemText>
                        <DropdownButton
                            buttonProps={() => ({
                                primary: false,
                                icon: true,
                                children: <IconDots />,
                            })}
                            popperProps={{}}
                        >
                            <DropdownActions>
                                <ActionItem onClick={() => removeCapsule(capsule)}>
                                    {t(LABELS.REMOVE_CAPSULE)}
                                </ActionItem>
                                <ActionItem onClick={() => openCapsuleModal(capsule)}>
                                    {t(LABELS.EDIT_CAPSULE)}
                                </ActionItem>
                            </DropdownActions>
                        </DropdownButton>
                    </CapsuleListItem>
                ))}
            </CapsuleList>
        </CapsuleSectionContainer>
    )
}