import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../types";
import { selectSelf } from "./utils";
import { UserInfo } from "types/user";
import { Storage } from 'utils/storage';
import { getUserInfo } from "state/thunks/auth/getUserInfo";
import { logout as logoutThunk } from '../thunks/auth/logout';

const initialState: UserState = {
    language: Storage.get('ug-language') || 'ru',
    user: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserInfo | null>) => {
            state.user = action.payload;
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
            Storage.set('ug-language', action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserInfo.fulfilled, (state, action) => {
                state.user = action.payload;
            })
            .addCase(getUserInfo.rejected, (state, action) => {
                state.user = null;
            })
            .addCase(logoutThunk.fulfilled, (state, action) => {
                state.user = null;
            });
    }
});

export const { setUser, setLanguage } = userSlice.actions;

export const selectCurrentUser = createSelector(selectSelf, state => state.user.user);
export const selectCurrentLanguage = createSelector(selectSelf, state => state.user.language);