import { WorkspaceEntity, WorkspaceEntityType } from "../../types/app";

export const entities: WorkspaceEntity[] = [{
    id: '1',
    type: WorkspaceEntityType.Chat,
    name: 'Prevent Object Movement Conditions',
    isStarred: true,
}, {
    id: '2',
    type: WorkspaceEntityType.Workspace,
    name: 'Research about wild birds',
    isStarred: true,
}, {
    id: '3',
    type: WorkspaceEntityType.Folder,
    name: 'Chats about birds behaviour',
    isStarred: true,
}, {
    id: '4',
    type: WorkspaceEntityType.Note,
    name: 'Debugging JavaScript Timeout Script',
    isStarred: true,
}]