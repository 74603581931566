import { FC, ReactNode, useRef, useState } from "react";
import { ClickAwayListener, Popper, PopperProps } from "@mui/base";
import { ButtonProps } from "../button/button";
import { StyledComponent } from "@emotion/styled";
import { StyledButton, Wrapper } from "./styles";
interface DropdownButtonProps {
    ButtonComponent?: FC<ButtonProps> | StyledComponent<ButtonProps>;
    buttonProps: (open: boolean) => Partial<ButtonProps>;
    children: ReactNode;
    popperProps: Partial<PopperProps>;
}

export const DropdownButton = ({
    ButtonComponent = StyledButton,
    buttonProps,
    children,
    popperProps,
}: DropdownButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLElement>(null);
    const handleButtonRef = (ref?: HTMLElement | null) => {
        if (ref) {
            // @ts-ignore
            anchorRef.current = ref;
        }
    };

    const toggleOpen = () => {
        setOpen(open => !open);
    }

    const handleClickAway = () => {
        if (open) {
            setOpen(false);
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Wrapper>
                <ButtonComponent primary onClick={toggleOpen} forwardRef={handleButtonRef} {...buttonProps(open)} />
                <Popper
                    anchorEl={anchorRef.current}
                    open={open}
                    placement="bottom-start"
                    {...popperProps}
                >
                    {/* <ClickAwayListener onClickAway={toggleOpen}> */}
                        <>
                            {children}
                        </>
                    {/* </ClickAwayListener> */}
                </Popper>
            </Wrapper>
        </ClickAwayListener>
    );
}