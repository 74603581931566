import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData, getDefaultHeaders, uploadWithProgress } from "../../../api"
import { WorkspaceEntity } from "../../../types/app";
import { HTTPMethod } from "../../../api/types";
import { apiEndpoints } from "../../../api/endpoints";

export interface UploadFileResponse {
    items: WorkspaceEntity[];
}

interface UploadFileArgs {
    file: File;
    collectionId: string;
    handlers: {
        onProgress: (progress: number) => void;
    };
}

export const action = 'files/upload'

export const uploadFile = createAsyncThunk(
    action,
    async (args: UploadFileArgs, thunkApi) => {
        const formData = new FormData();
        formData.append('collection_id', args.collectionId);
        formData.append('file_data', args.file);
        const headers = getDefaultHeaders();
        headers.delete('Content-type');

        // headers.append('Content-Type', 'multipart/form-data');
        const promise = uploadWithProgress(
            apiEndpoints.upload,
            formData,
            {
                ...args.handlers,
                signal: thunkApi.signal,
            },
            headers,
        );

        return promise;
    }
)