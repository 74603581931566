import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../../api"
import { apiEndpoints } from "../../../api/endpoints";
import Storage from "../../../utils/storage";
import { appConfig } from "../../../constants/config";
import { HTTPMethod } from "../../../api/types";

export interface LoginResponse {
    token: string;
}

interface LoginArgs {
    login: string;
    password: string;
}

export const action = 'auth/attemptLogin'

export const login = createAsyncThunk(
    action,
    async (args: LoginArgs, thunkApi) => {

        const response = await fetchData<LoginResponse>(
            apiEndpoints.auth.login,
            HTTPMethod.POST,
            JSON.stringify({ email: args.login, password: args.password}),
        );

        Storage.set(appConfig.storageKey, response.token);
        return response;
    },
)