import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { RawIntegrationResponse } from "./types";
import { decorateIntegration } from "./utils";

export interface IntegrationListResponse {
    items: RawIntegrationResponse[];
}

interface IntegrationListArgs {
}

export const action = 'integration/list'

export const fetchIntegrationList = createAsyncThunk(
    action,
    async (args: IntegrationListArgs, thunkApi) => {
        const response = await callApi<IntegrationListResponse>(rpcMethods.integrations.list, args);
        return response.items.map(decorateIntegration);
    },
)