import { Navigation } from ".";
import { ActionsSectionContainer } from "./sections/actions/container";
import { FilesSectionContainer } from "./sections/files/container";
import { ItemsSectionContainer } from "./sections/items/container";
import { WorkspaceSectionContainer } from "./sections/workspace/container";
import { SettingsContainer } from "./sections/settings/container";

export const NavigationContainer = () => {

    return (
        <Navigation >
            <WorkspaceSectionContainer />
            <ActionsSectionContainer />
            <FilesSectionContainer />
            <ItemsSectionContainer />
        </Navigation>
    )
}

export const SettingsNavigationContainer = () => {
    return (
        <Navigation withBackButton>
            <SettingsContainer />
        </Navigation>
    )
}

export default NavigationContainer;