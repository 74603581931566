import { useSelector } from "react-redux";
import { Sidebar } from "."
import { logout } from "state/thunks/auth/logout";
import { useAppDispatch } from "../../../state/store";
import { selectCapsules } from "../../../state/slices/capsules";
import { useStatedAutoRequest, useStatedRequest } from "../../../api/hooks/useStatedRequest";
import { fetchCollectionsList } from "../../../state/thunks/collections/fetchCollectionsList";
import { addCollection } from "../../../state/thunks/collections/addCollection";
import { uploadFile } from "../../../state/thunks/files/uploadFile";
import { fetchFilesList } from "../../../state/thunks/files/fetchFileList";
import { selectEnabledCapsules, toggleCapsule } from "../../../state/slices/chat";
import { deleteFile } from "../../../state/thunks/files/deleteFile";
import { deleteCollection } from "../../../state/thunks/collections/deleteCollection";
import { renameCollection } from "../../../state/thunks/collections/renameCollection";
import { useNavigate } from "react-router";
import { routePath } from "../../../router/path";
import { selectCurrentLanguage, setLanguage } from "state/slices/user";
import { useTranslation } from "react-i18next";

export const SidebarContainer = () => {
    const { i18n } = useTranslation();
    const { isLoading: isFetchingCapsules } = useStatedAutoRequest(fetchCollectionsList, {});
    const { invoke: createCapsule, isLoading: isCreatingCapsule } = useStatedRequest(addCollection);
    const { invoke: renameCapsule, isLoading: isRenamingCapsule } = useStatedRequest(renameCollection);
    const { invoke: uploadFileToCapsule } = useStatedRequest(uploadFile);
    const { invoke: getCapsuleFiles, isLoading: isFetchingFiles } = useStatedRequest(fetchFilesList);
    const { invoke: removeFile } = useStatedRequest(deleteFile);
    const { invoke: removeCapsule } = useStatedRequest(deleteCollection);
    const capsules = useSelector(selectCapsules);
    const enabledCapsules = useSelector(selectEnabledCapsules);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleToggleCapsuleState = (capsuleId: string) => {
        dispatch(toggleCapsule(capsuleId));
    }

    const handleLogout = () => {
        dispatch(logout({}));
    }

    const handleChangeLanguage = (language: string) => {
        dispatch(setLanguage(language));
        i18n.changeLanguage(language);
    }

    const handleCreateCapsule = async (name: string) => {
        const capsule = await createCapsule({ name });

        return capsule?.payload;
    }

    const handleRenameCapsule = async (capsuleId: string, name: string) => {
        const capsule = await renameCapsule({
            name,
            collectionId: capsuleId,
        });

        return capsule?.payload;
    }

    const goToSettings = () => {
        navigate(routePath.settings.root);
    }

    const handleUploadFile = (
        file: File,
        capsuleId: string,
        handlers: {
            onProgress: (progress: number) => void;
        }
    ) => {
        const promise = uploadFileToCapsule({ file, collectionId: capsuleId, handlers });
        return promise!;
    }

    const handleDeleteFile = async (id: string) => {
        const response = await removeFile({ fileId: id });
        return response;
    };

    const handleDeleteCapsule = async (id: string) => {
        const response = await removeCapsule({ collectionId: id });
        return response;
    }

    const handleFetchCapsuleFiles = async (id: string) => {
        const response = await getCapsuleFiles({ collectionId: id });
        return response || [];
    }

    return <Sidebar
        capsules={capsules}
        enabledCapsules={enabledCapsules}
        logout={handleLogout}
        goToSettings={goToSettings}
        language={currentLanguage}
        onChangeLanguage={handleChangeLanguage}
        isFetching={isFetchingCapsules}
        capsuleControls={{
            toggleCapsuleState: handleToggleCapsuleState,
            onUploadFile: handleUploadFile,
            onCreateCapsule: handleCreateCapsule,
            onRenameCapsule: handleRenameCapsule,
            onDeleteFile: handleDeleteFile,
            onDeleteCapsule: handleDeleteCapsule,
            getCapsuleFiles: handleFetchCapsuleFiles,
            isCreatingCapsule,
            isRenamingCapsule,
            isFetchingFiles,
        }}
     />;
};

export default SidebarContainer;