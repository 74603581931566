import { useTranslation } from "react-i18next";
import { Button } from "components/buttons";
import { Modal } from "components/modal";
import { ModalContainer, ModalHeader, ModalPanel } from "components/modal/styles";
import { LABELS } from "./keys";
import { Fields, NameLabel } from "./styles";
import { Input } from "components/inputs/input";
import { ChangeEvent, useState, KeyboardEvent } from "react";

import { KeyboardKeys } from "constants/keyboard";

interface AddSpaceModalProps {
    open: boolean;
    isCreating: boolean;
    onClose: () => void;
    onCreateSpace: (args: { name: string; description?: string; }) => void;
}

export const AddSpaceModal = ({ open, onClose, onCreateSpace, isCreating }: AddSpaceModalProps) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const { t } = useTranslation();


    const handleClose = () => {
        onClose();
    }

    const handleSubmit = async () => {
        await onCreateSpace({ name, description });
        onClose();
    };

    const handleNameKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardKeys.Enter) {
            handleSubmit();
        }
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    
    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const isSubmitDisabled = !Boolean(name) || isCreating;

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalContainer>
                <Button flat onClick={handleClose}>
                    {t(LABELS.CLOSE_BUTTON_TITLE)}
                </Button>
                <ModalPanel>
                    <ModalHeader>
                        {t(LABELS.TITLE)}
                    </ModalHeader>
                    <Fields>
                        <NameLabel>{t(LABELS.NAME_LABEL)}</NameLabel>
                        <Input
                            fullWidth
                            placeholder={t(LABELS.NAME_PLACEHOLDER)}
                            value={name}
                            disabled={isCreating}
                            onChange={handleNameChange}
                            onKeyDown={handleNameKeyDown}
                        />
                        <Input
                            fullWidth
                            // @ts-ignore
                            multiline
                            disabled={isCreating}
                            placeholder={t(LABELS.DESCRIPTION_PLACEHOLDER)}
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </Fields>
                    <Button secondary disabled={isSubmitDisabled} onClick={handleSubmit}>
                        {t(LABELS.SUBMIT_LABEL)}
                    </Button>
                </ModalPanel>
            </ModalContainer>
        </Modal>
    );
}