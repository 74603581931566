import styled from "@emotion/styled/macro";
import { ButtonType } from "./types";
import { Path } from "../../icons/styles";
import { keyframes, Theme } from "@emotion/react";

const primaryButtonStyles = (props: { theme: Theme }) => ({
    backgroundColor: 'transparent',
    display: 'flex',
    height: 34,
    padding: '0px 15px 0px 4px',
    alignItems: 'center',
    gap: 12,
    borderRadius: 60,
    border: '1px solid rgba(255, 255, 255, 0.25)',
    color: props.theme.colors.text.primary,
    opacity: 0.5,
    transition: 'all .3s ease',
    ':hover': {
        opacity: 1,
    },
});

const buttonBackgroundAnimation = keyframes`
    0% {
        background: radial-gradient(223.55% 102.52% at 0% 50%, #ADCEFF 0%, #4B55A9 33.72%, #311896 66.67%, #B784F9 100%);
    }
    100%: {
        background: radial-gradient(223.55% 102.52% at 0% 50%, #B784F9 0%, #311896 33.33%, #4B55A9 66.28%, #ADCEFF 100%);
    }
`

const secondaryButtonStyles = (props: { theme: Theme, disabled?: boolean }) => ({
    border: 0,
    borderRadius: 12,
    fontSize: 14,
    lineHeight: 1.1,
    height: 40,
    color: props.theme.colors.white,
    background: props.disabled
        ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), radial-gradient(223.55% 102.52% at 0% 50%, #ADCEFF 0%, #4B55A9 33.72%, #311896 66.67%, #B784F9 100%)'
        : 'radial-gradient(223.55% 102.52% at 0% 50%, #ADCEFF 0%, #4B55A9 33.72%, #311896 66.67%, #B784F9 100%)',
    transition: 'all .3s ease',
    // @ts-ignore
    [Path]: {
        fill: props.theme.colors.heading,
    },
});

const flatButtonStyles = (props: { theme: Theme }) => ({
    backgroundColor: 'transparent',
    color: props.theme.colors.text.primary,
    fontSize: 12,
    textDecoration: 'underline',
    // @ts-ignore
    [Path]: {
        fill: props.theme.colors.accent,
    },
    opacity: 0.5,
    transition: 'all .3s ease',
    ':hover': {
        opacity: 1,
    },
});

const iconButtonStyles = (props: { theme: Theme }) => ({
    backgroundColor: 'transparent',
    color: props.theme.colors.text.primary,
    border: 0,
    borderRadius: 8,
    padding: 8,
    // @ts-ignore
    [Path]: {
        color: props.theme.colors.text.primary,
    },
});

const stylesMap: Record<ButtonType, (props: { theme: Theme }) => any> = {
    [ButtonType.Primary]: primaryButtonStyles,
    [ButtonType.Secondary]: secondaryButtonStyles,
    [ButtonType.Flat]: flatButtonStyles,
    [ButtonType.Icon]: iconButtonStyles,
};

export const ButtonRoot = styled.button<{ buttonType: ButtonType, disabled?: boolean }>(() => ({
    padding: 0,
    border: 0,
    margin: 0,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
}), (props) => stylesMap[props.buttonType](props) );