import { appConfig } from "../constants/config";
import { HTTPMethod } from "./types";
import { responseMock } from "./responseMocks";
import { Storage } from '../utils/storage';
import { apiEndpoints } from "./endpoints";
import { loginResponse } from "./responseMocks/loginResponse";

const getAuthToken = () => Storage.get(appConfig.storageKey)

export const getDefaultHeaders = () => {
    const defaultHeaders = new Headers()
    defaultHeaders.append('Content-type', 'application/json')
    defaultHeaders.append('Authorization', `bearer ${getAuthToken()}`);
    return defaultHeaders;
}

export const uploadWithProgress = <T>(
    url: string,
    body: FormData,
    handlers: {
        onProgress?: (percent: number) => void,
        onCancel?: () => void,
        signal?: AbortSignal,
    },
    headers?: Headers,
): Promise<T> => {
    const requestUrl = [appConfig.apiUrl, url].join('');
    
    const xhr = new XMLHttpRequest();
    xhr.open(HTTPMethod.POST, requestUrl, true);
    const usedHeaders = headers ?? getDefaultHeaders()

    const output = new Promise<T>((resolve, reject) => {
        // @ts-ignore
        for (const entry of usedHeaders.entries()) {
            xhr.setRequestHeader(entry[0], entry[1]);
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            } else {
                reject('Upload failed');
            }
        }

        xhr.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                handlers.onProgress?.(percentComplete);
            }
        }

        handlers?.signal?.addEventListener('abort', () => {
            xhr.abort();
        });

        xhr.onerror = (error: unknown) => {
            reject(error);
        }
    });

    xhr.send(body);

    return output;
};

export const fetchData = async <T>(url: string, method: HTTPMethod, body: any, headers?: Headers): Promise<T> => {
    const requestUrl = [appConfig.apiUrl, url].join('');
    const response = await fetch(requestUrl, {
        method,
        headers: headers ?? getDefaultHeaders(),
        body,
        cache: 'no-cache',
    });

    // temp login hack
    if (appConfig.mockResponses && url === apiEndpoints.auth.login) {
        await new Promise((resolve, reject) => setTimeout(resolve, 1000));
        return loginResponse as T;
    }

    if (response.ok) {
        const responseData = await response.json();
        if (responseData.error) {
            throw responseData.error;
        }

        return responseData;
    } else {
        const responseData = await response.json();
        if (responseData.error) {
            throw new Error(responseData.error);
        }

        throw new Error(`API call failed: [${response.status}] ${response.statusText}`);
    }
};

export const callApi = async <T>(method: string, params: Record<any, any> = {}): Promise<T> => {
    // if (!getAuthToken()) {
    //     throw new Error('no auth token provided');
    // }

    if (appConfig.mockResponses) {
        await new Promise((resolve, reject) => setTimeout(resolve, 1000));
        return responseMock[method] || {};
    }

    const requestData = JSON.stringify({
        jsonrpc: '2.0',
        method,
        params,
        id: Date.now(), // Unique ID for each request
    });

    const response = await fetchData<{ result: T }>(apiEndpoints.rpc, HTTPMethod.POST, requestData);
    return response.result as T;  
};