import { useState } from "react";
import { WorkspaceEntity } from "../../types/app";
import { Button } from "../buttons";
import { IconGrid } from "../icons/grid";
import { IconList } from "../icons/list";
import { EntityGridItem } from "./item/grid";
import { EntityListItem } from "./item/list";
import { ActionBar, Actions, FilterContainer, GridContainer, ListContainer } from "./styles";
import { LABELS } from "./keys";
import { useTranslation } from "react-i18next";

interface ItemListProps {
    items: WorkspaceEntity[];
    onItemClick?: (item: WorkspaceEntity) => void;
}

enum LayoutVariant {
    Grid,
    List,
}

export const EntityList = ({ items, onItemClick }: ItemListProps) => {
    const { t } = useTranslation();
    const [layout, setLayout] = useState<LayoutVariant>(LayoutVariant.Grid);

    const changeLayout = (variant: LayoutVariant) => () => {
        setLayout(variant);
    }

    if (layout === LayoutVariant.Grid) {
        return (
            <GridContainer>
                <ActionBar>
                    <FilterContainer>
                        {t(LABELS.FILTER_PLACEHOLDER)}
                    </FilterContainer>
                    <Actions>
                        <Button icon onClick={changeLayout(LayoutVariant.Grid)}>
                            <IconGrid width={16} height={16} />
                        </Button>
                        {/* <Button icon onClick={changeLayout(LayoutVariant.List)}>
                            <IconList width={16} height={16} />
                        </Button> */}
                    </Actions>
                </ActionBar>
                {items.map((item) => <EntityGridItem key={item.id} onClick={onItemClick} {...item} /> )}
            </GridContainer>
        );
    }

    return (
        <ListContainer>
            <ActionBar>
                <FilterContainer>
                    {t(LABELS.FILTER_PLACEHOLDER)}
                </FilterContainer>
                <Actions>
                <Button icon onClick={changeLayout(LayoutVariant.Grid)}>
                        <IconGrid width={16} height={16} />
                    </Button>
                    <Button icon onClick={changeLayout(LayoutVariant.List)}>
                        <IconList width={16} height={16} />
                    </Button>
                </Actions>
            </ActionBar>
            {items.map((item) => <EntityListItem onClick={onItemClick} {...item} /> )}
        </ListContainer>
    )
}