import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";

export interface DeleteCollectionResponse {
    is_success: boolean;
    collection_id: string;
}

interface DeleteCollectionArgs {
    collectionId: string;
}

export const action = 'collections/delete'

export const deleteCollection = createAsyncThunk(
    action,
    async (args: DeleteCollectionArgs, thunkApi) => {
        const response = await callApi<DeleteCollectionResponse>(
            rpcMethods.collections.delete,
            { collection_id: args.collectionId },
        );
        return response.collection_id;
    },
)