import { Path, Svg } from "./styles"
import { IconProps } from "./types";

const path = [
    'M7.97265 4.9975C8.12286 4.9975 8.27306 5.0575 8.39323 5.1675L13.3099 10.0075C13.5402',
    '10.2375 13.5402 10.5975 13.3099 10.8275C13.0796 11.0575 12.7091 11.0575 12.4788',
    '10.8275L7.98267 6.3975L3.48654 10.8275C3.25622 11.0575 2.88572 11.0575 2.6554 10.8275C2.42509',
    '10.5975 2.42509 10.2375 2.6554 10.0075L7.57211 5.1675C7.69227 5.0575 7.84248 4.9975 7.99268',
    '4.9975L7.97265 4.9975Z'
].join(' ');

export const IconChevron = ({
    width = 16,
    height = 16,
}: IconProps) => (
    <Svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <Path d={path} />
    </Svg>
)