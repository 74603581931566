import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../types";
import { selectSelf } from "./utils";
import Storage from "../../utils/storage";
import { appConfig } from "../../constants/config";
import { login } from "../thunks/auth/login";
import { logout } from '../thunks/auth/logout';
import { getUserInfo } from "state/thunks/auth/getUserInfo";
import { showError } from "providers/notifications/notificationProvider";

const initialState: AuthState = {
    token: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.token;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.token = null;
                Storage.remove(appConfig.storageKey);
            })
            .addCase(getUserInfo.rejected, (state, action) => {
                state.token = null;
                Storage.remove(appConfig.storageKey);
                showError(action.error.message || 'Invalid token');
            });
    }
});

export const { setAuthToken } = authSlice.actions;

export const selectAuthToken = createSelector(selectSelf, state => state.auth.token);