import { TableRowProps } from "components/table/types";
import { TableCell, TableCellContent } from "components/table/styles";
import { Actions, CreatedDate, TableCellContentSmall, Name, CellWithImage, IconImage } from "./styles";
import { Button } from "components/buttons";
import { KEYS, LABELS } from "../keys";
import { useTranslation } from "react-i18next";
import { Integration } from "types/app";
import dayjs from "dayjs";

export const UserTableRow = ({
    item,
    columns,
    passedProps,
}: TableRowProps<Integration>) => {
    const { t } = useTranslation();

    const displayKey = (item.updatedAt !== item.createdAt) ? LABELS.UPDATED_AT : LABELS.CREATED_AT;
    const displayDate = dayjs((item.updatedAt ?? item.createdAt) * 1000).format(KEYS.DATE_FORMAT);
    const displayDateLabel = t(displayKey, { date: displayDate });

    return (
        <>
            <TableCell column={1}>
                <CellWithImage column={1}>
                    <IconImage src={item.icon ?? undefined} />
                    <Name>{item.name}</Name>
                </CellWithImage>
            </TableCell>
            <TableCell column={2}>
                <TableCellContentSmall column={2}>
                    {item.description}
                </TableCellContentSmall>
            </TableCell>
            <TableCell column={3}>
                <TableCellContentSmall column={3}>
                    {item.status}
                </TableCellContentSmall>
            </TableCell>
            <TableCell column={4}>
                <TableCellContentSmall column={4}>
                    <CreatedDate>
                        {displayDateLabel}
                    </CreatedDate>
                </TableCellContentSmall>
            </TableCell>
            <TableCell column={5}>
                <TableCellContent column={5}>
                    <Actions>
                        {/* <Button flat onClick={passedProps?.toggleIntegration?.(item.id)}>
                            {t(LABELS.ENABLE_INTEGRATION)}
                        </Button> */}
                        <Button flat onClick={passedProps?.viewIntegration?.(item.id)}>
                            {t(LABELS.VIEW_INTEGRATION)}
                        </Button>
                        <Button flat onClick={passedProps?.removeIntegration?.(item.id)}>
                            {t(LABELS.REMOVE_INTEGRATION)}
                        </Button>
                    </Actions>
                </TableCellContent>
            </TableCell>
        </>
    );
}