import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { ServerCapsuleItem } from "./types";
import { decorateCapsule } from "./utils";

export interface RenameCollectionResponse {
    data: ServerCapsuleItem;
}

interface RenameCollectionArgs {
    name: string;
    collectionId: string;
}

export const action = 'collections/rename'

export const renameCollection = createAsyncThunk(
    action,
    async (args: RenameCollectionArgs, thunkApi) => {
        const response = await callApi<RenameCollectionResponse>(
            rpcMethods.collections.rename,
            { new_name: args.name, collection_id: args.collectionId },
        );
        return decorateCapsule(response.data);
    },
)