import { CapsuleItem } from "../../../types/app";
import { ServerCapsuleItem } from "./types";

export const decorateCapsule = (data: ServerCapsuleItem): CapsuleItem => ({
    id: data.id,
    name: data.name,
    customInstructions: data.custom_instructions,
    clientId: data.client_id,
    organizationId: data.organization_id,
    size: 0,
    status: data.status,
    itemCount: 0,
})