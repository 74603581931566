import styled from "@emotion/styled";


export const ListItemContainer = styled.div(() => ({

}));

export const GridItemContainer = styled.div(() => ({
    borderRadius: 16,
    border: '1px solid rgba(255, 255, 255, 0.25)',
    background: '#030508',
    display: 'flex',
    padding: 10,
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: 120,
    width: 280,
    boxSizing: 'border-box' as const,
}));

export const Head = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

export const Info = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    gap: 5,
    padding: 10,
}));

export const Name = styled.h3((props) => ({
    padding: 0,
    margin: 0,
    color: props.theme.colors.text.primary,
    fontSize: 14,
    lineHeight: 1.1,
}));

export const InfoText = styled.span((props) => ({
    fontSize: 12,
    color: props.theme.colors.text.primary,
    opacity: 0.4,
    lineHeight: 1.1,
}));