import styled from "@emotion/styled";

export const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
}));

export const ChatMessages = styled.div(() => ({
    flex: 1,
    maxWidth: 560,
    width: '100%',
    maxHeight: 'calc(100vh - 50px)',
    overflowX: 'hidden' as const,
    overflowY: 'hidden' as const,
    boxSizing: 'border-box' as const,
    position: 'absolute' as const,
    top: 0,
    // '::-webkit-scrollbar': {
    //     display: 'none',
    // },
}));

export const Scrollable = styled.div(() => ({
    width: '100%',
    paddingRight: 22,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    maxHeight: '100vh',
    overflowY: 'auto' as const,
    gap: 20,
    '::-webkit-scrollbar': {
        display: 'none',
    },
}));

export const Bumper = styled.div(() => ({
    minHeight: 100,
    width: '100%',
}))