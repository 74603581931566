import { Path, Svg } from "./styles"
import { IconProps } from "./types";

interface IconStarProps extends IconProps {
    filled?: boolean
}

export const IconStar = ({
    width = 14,
    height = 13,
    filled,
}: IconStarProps) => filled ? 
    (
        <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
            <Path d="M9.20934 2.01092L10.4412 4.49501C10.6092 4.84081 11.0571 5.17249 11.4351 5.236L13.6679 5.61003C15.0957 5.84997 15.4317 6.89442 14.4028 7.92475L12.667 9.6749C12.373 9.9713 12.212 10.5429 12.303 10.9522L12.7999 13.1187C13.1919 14.8336 12.289 15.497 10.7842 14.6007L8.6914 13.3516C8.31344 13.1258 7.69051 13.1258 7.30556 13.3516L5.21279 14.6007C3.71495 15.497 2.80505 14.8266 3.19701 13.1187L3.69395 10.9522C3.78494 10.5429 3.62396 9.9713 3.33 9.6749L1.59419 7.92475C0.5723 6.89442 0.901264 5.84997 2.32911 5.61003L4.56186 5.236C4.93282 5.17249 5.38077 4.84081 5.54875 4.49501L6.78061 2.01092C7.45254 0.663025 8.54442 0.663025 9.20934 2.01092Z" fill="#FFD02B" stroke="#FFD02B" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    )
    : (
        <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
            <Path d="M9.20935 2.01092L10.4412 4.49501C10.6092 4.84081 11.0571 5.17249 11.4351 5.236L13.6679 5.61003C15.0957 5.84997 15.4317 6.89442 14.4028 7.92475L12.667 9.6749C12.373 9.9713 12.212 10.5429 12.303 10.9522L12.7999 13.1187C13.1919 14.8336 12.289 15.497 10.7842 14.6007L8.6914 13.3516C8.31344 13.1258 7.69051 13.1258 7.30556 13.3516L5.21279 14.6007C3.71495 15.497 2.80505 14.8266 3.19701 13.1187L3.69395 10.9522C3.78494 10.5429 3.62396 9.9713 3.33 9.6749L1.59419 7.92475C0.5723 6.89442 0.901264 5.84997 2.32911 5.61003L4.56186 5.236C4.93282 5.17249 5.38077 4.84081 5.54875 4.49501L6.78061 2.01092C7.45254 0.663025 8.54442 0.663025 9.20935 2.01092Z" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    )