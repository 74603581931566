import { Container, Content, Label, TableHead, Title } from "./styles";
import { integrationTableColumns, LABELS } from "./keys";
import { useTranslation } from "react-i18next";
import { Button } from "components/buttons";
import { Table } from "components/table";
import { Integration } from "types/app";
import { UserTableRow } from "./components/userTableRow";

interface IntegrationSettingsProps {
    onAdd: () => void;
    integrations: Integration[];
    viewIntegration: (id: string) => () => void;
    removeIntegration: (id: string) => () => void;
    toggleIntegration: (id: string) => () => any;
}

export const IntegrationSettings = ({
    onAdd,
    integrations,
    viewIntegration,
    removeIntegration,
    toggleIntegration,
}: IntegrationSettingsProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Title>
                {t(LABELS.TITLE)}
            </Title>
            <Content>
                <TableHead>
                    <Label>{t(LABELS.INTEGRATION_CHANNELS)}</Label>
                    <Button flat onClick={onAdd}>
                        {t(LABELS.ADD_INTEGRATION)}
                    </Button>
                </TableHead>
                <Table<Integration>
                    fullWidth
                    items={integrations}
                    columns={integrationTableColumns}
                    passedProps={{
                        viewIntegration,
                        removeIntegration,
                        toggleIntegration,
                    }}
                    TableRowComponent={UserTableRow}
                />
            </Content>
        </Container>
    );
}