import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../../api"
import { apiEndpoints } from "../../../api/endpoints";
import { HTTPMethod } from "../../../api/types";

export interface LogoutResponse {
    token: string;
}

interface LogoutArgs {
}

export const action = 'auth/logout'

export const logout = createAsyncThunk(
    action,
    async (args: LogoutArgs, thunkApi) => {
        const response = await fetchData<LogoutResponse>(
            apiEndpoints.auth.logout,
            HTTPMethod.POST,
            '{}',
        );
        return response;
    },
)