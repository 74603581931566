import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconClip = ({
    width = 13,
    height = 18,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 18">
        <Path d="M6.5 9.42105V6.47368C6.5 4.84842 7.73357 3.52632 9.25 3.52632C10.7664 3.52632 12 4.84842 12 6.47368V11.1053C12 14.3642 9.54071 17 6.5 17C3.45929 17 1 14.3642 1 11.1053L1 6.05263C1 3.26526 3.11357 1 5.71429 1" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)