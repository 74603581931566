import React from 'react';

interface HeaderProps {
};


export const Header = ({}: HeaderProps) => {
    return (
        null
    );
}