import styled from "@emotion/styled";
import { TableColumn } from "./types";

export const TableRoot = styled.div<{ columns: TableColumn[], fullWidth?: boolean }>(({ columns, fullWidth }) => ({
    display: 'grid',
    width: fullWidth ? '100%' : 'auto',
    gridTemplateColumns: columns
        .filter(column => !column.hidden)
        .map(column => [column?.space || 1, 'fr'].join(''))
        .join(' '),
}))

export const TableCell = styled.div<{ column: number }>(({ theme, column }) => ({
    display: 'flex',
    color: theme.colors.text.primary,
    gridColumn: column,
    justifyContent: 'stretch',
    alignItems: 'center',
    padding: '8px 0',
    borderBottom: `1px solid ${theme.colors.border.primary}3f`,
}));

export const TableCellContent = styled.div<{ column: number }>(({ theme, column }) => ({
    padding: '0 10px',
    minHeight: 42,
    fontSize: 12,
    lineHeight: 1.1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderLeft: column > 1 ? `1px solid ${theme.colors.border.primary}` : 'none',
}));

export const TableRow = styled.div<{ columns: TableColumn[] }>(({ columns }) => ({
    display: 'grid',
    gridColumn: `1 / span ${columns.length}`,
    gridTemplateColumns: columns
        .filter(column => !column.hidden)
        .map(column => [column?.space || 1, 'fr'].join(''))
        .join(' '),
}));