import { ReactNode } from 'react';
import { BackButton, Container, Header, Logo, SiteLink } from './styles';
import { useTranslation } from 'react-i18next';
import { LABELS } from './keys';
import { routePath } from 'router/path';
import { IconChevron } from 'components/icons/chevron';

interface NavigationProps {
    children?: ReactNode | ReactNode[] | string | null;
    withBackButton?: boolean;
};

export const Navigation = ({ children, withBackButton }: NavigationProps) => {
    const { t } = useTranslation();

    if (withBackButton) {
        return (
            <Container>
            <Header>
                <SiteLink to={routePath.root}>
                    <BackButton>
                        <IconChevron />
                        {t(LABELS.GO_BACK)}
                    </BackButton>
                </SiteLink>
            </Header>
            {children}
        </Container>
        )
    }
    return (
        <Container>
            <Header>
                {/* <SiteLink to="/">
                    <Logo src={upgraideLogo} />
                </SiteLink> */}
            </Header>
            {children}
        </Container>
    );
}