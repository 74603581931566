import { FC } from "react";
import { TableCell, TableCellContent, TableRoot } from "./styles";
import { TableProps, TableRowProps } from "./types";

export const DefaultTableRow = <T extends object>({
    item,
    columns,
    passedProps,
}: TableRowProps<T>) => (
    columns.map(
        (column, colIndex) => (
            <TableCell column={colIndex + 1}>
                <TableCellContent column={colIndex + 1}>
                    {
                        // @ts-ignore
                        item[column.key]
                    }
                </TableCellContent>
            </TableCell>
        )
    )
);

export const Table = <T extends { id: string }>({
    items,
    columns,
    passedProps,
    TableRowComponent,
    fullWidth,
}: TableProps<T>) => {
    const RowComponent = (TableRowComponent ?? DefaultTableRow) as FC<TableRowProps<T>>;

    return (
        <TableRoot fullWidth={fullWidth} columns={columns}>
            {items.map((item, index) => <RowComponent key={item?.id} item={item} columns={columns} passedProps={passedProps} />)}
        </TableRoot>
    );
}