import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { decorateMessage } from "./utils";
import { RawMessage } from "./types";

export interface FetchChatHistoryResponse {
    messages: RawMessage[];
}

interface FetchChatHistoryArgs {
    chatId: string;
}

export const action = 'chat/history'

export const fetchChatHistory = createAsyncThunk(
    action,
    async (args: FetchChatHistoryArgs, thunkApi) => {
        const response = await callApi<FetchChatHistoryResponse>(rpcMethods.chat.history, {
            chat_id: args.chatId,
        });

        await new Promise((resolve, reject) => setTimeout(resolve, 3000));

        return response.messages.map(decorateMessage);
    },
)