import styled from '@emotion/styled';

import {
    Select as BaseSelect,
} from '@mui/base/Select';

import { Option as BaseOption } from '@mui/base/Option';

export const SelectRoot = styled(BaseSelect)((props) => ({
    backgroundColor: props.theme.colors.background.primary,
    minWidth: 50,
    padding: 8,
    borderRadius: 8,
    border: `1px solid ${props.theme.colors.border.primary}`,
    // boxShadow: 'none',
    color: '#fff',
    margin: 0,
    cursor: 'pointer',
    fontSize: 12,
    ui: {
        margin: 0,
    },
}));

export const SelectOption = styled(BaseOption)(() => ({
    color: '#fff',
    minWidth: 50,
    cursor: 'pointer',
    fontSize: 12,
}));

export const SelectOptions = styled.ul((props) => ({
    backgroundColor: props.theme.colors.background.primary,
    border: `1px solid ${props.theme.colors.border.primary}`,
    padding: '8px 12px',
    listStyle: 'none',
    borderRadius: 5,
    margin: 0,
    marginTop: 2,
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
}));