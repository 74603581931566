import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { FileResponse } from "./types";
import { decorateFile } from "./utils";

export interface FetchFileListResponse {
    items: FileResponse[];
}

interface FetchFileListArgs {
    collectionId: string;
}

export const action = 'files/list'

export const fetchFilesList = createAsyncThunk(
    action,
    async (args: FetchFileListArgs, thunkApi) => {
        const response = await callApi<FetchFileListResponse>(
            rpcMethods.files.list,
            { collection_id: args.collectionId },
        );

        return response.items.map(decorateFile);
    },
)