import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconArrow = ({
    width = 16,
    height = 12,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 12" fill="none">
        <Path d="M9.95215 1.06812L14.5939 5.70987L9.95215 10.3516" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M1.59375 5.70984H14.4637" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)