import styled from '@emotion/styled/macro';
import { Button } from '../../../components/buttons';
import { Path, Svg } from '../../../components/icons/styles';
import { Input } from '../../../components/inputs/input';

export const HeaderContainer = styled.div((props) => ({
    gridColumn: 'navigation-end / sidebar-start',
    gridRow: 'header-start / header-end',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'visible',
    zIndex: 100,
}));

export const CentralSection = styled.div(() => ({
    flex: 4,
    display: 'flex',
    justifyContent: 'center',
}))

export const ModelSelectContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    padding: 12,
    minWidth: 250,
    borderRadius: 12,
}));

export const ModelSelectLabel = styled.span((props) => ({
    fontFamily: 'Helvetica',
    fontSize: 16,
    color: props.theme.colors.text.primary,
}));

export const ControlsContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
    flex: 1,
}));

export const ProfileButton = styled(Button)(() => ({
    width: 48,
    height: 48,
}));

export const ModelPanel = styled.div((props) => ({
    padding: 12,
    borderRadius: 12,
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    minWidth: 280,
    gap: 8,
    border: `1px solid ${props.theme.colors.text.primary}`,
    backdropFilter: 'blur(10px)',
}));

export const ModelTypeButtons = styled.div(() => ({
    display: 'flex',
}));

export const ModelTypeButton = styled(Button)<{ active?: boolean }>((props) => ({
    flex: '1 0 calc(50% - 8px)',
}))

export const SearchBarContainer = styled.div(() => ({
    flex: 1,
    display: 'flex',
}))

export const SearchBar = styled(Input)(() => ({
    flex: 1,
    input: {
        flex: 1,
    }
}));

export const ModelList = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    minHeight: 200,
    gap: 8,
}));

export const ModelItemPreview = styled.div<{ open?: boolean }>((props) => ({
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '115%',
    color: props.theme.colors.text.dark,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    // @ts-ignore
    [Svg]: {
        transform: props.open ? 'none' : 'rotate(180deg)',
    }
}))

export const ModelItem = styled(Button)((props) => ({
    padding: '8px 12px',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '115%',
    color: props.theme.colors.text.dark,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    '&: hover': {
        backgroundColor: props.theme.colors.background.primary,
    },
}))

export const ModelItemIcon = styled.img(() => ({
    width: 16,
    height: 16,
}))

export const SearchAdornment = styled.div((props) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    [Path]: {
        fill: props.theme.colors.heading,
    }
}))
