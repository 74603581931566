import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { StyledNotification } from "./styles";

export const showNotification = (message: string) => {
    enqueueSnackbar(message);
}

export const showWarning = (message: string) => {
    enqueueSnackbar(message, { variant: 'warning' });
}

export const showError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
}

export const showInfoNotification = (message: string) => {
    enqueueSnackbar(message, { variant: 'info' });
}

export const showSuccessNotification = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' });
}

export const NotificationProvider = () => {
    return (
        <SnackbarProvider
            hideIconVariant
            Components={{
                info: StyledNotification,
                success: StyledNotification,
                warning: StyledNotification,
                error: StyledNotification,
                default: StyledNotification,
            }}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
        />
    );
}