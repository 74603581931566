import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconSearch = ({
    width = 12,
    height = 12,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12">
        <Path d="M5.64062 9.78125C8.20357 9.78125 10.2812 7.70357 10.2812 5.14062C10.2812 2.57768 8.20357 0.5 5.64062 0.5C3.07768 0.5 1 2.57768 1 5.14062C1 7.70357 3.07768 9.78125 5.64062 9.78125Z" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M11.3125 10.8125L9.9375 9.4375" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)