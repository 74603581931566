import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntitiesState } from "../types";
import { selectSelf } from "./utils";
import { entities } from "../../api/responseMocks/entities";
import { fetchDialogs } from "../thunks/chat/fetchDialogs";
import { DialogRecord, SpaceRecord, WorkspaceEntityType } from "../../types/app";
import { createSpace } from "state/thunks/spaces/addSpace";
import { createChat } from "state/thunks/chat/createChat";

const initialState: EntitiesState = {
    workspaces: [],
    chats: [],
    folders: [],
    notes: [],
    entities: [],
    starredEntities: entities,
}

export const entitiesSlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {
        setWorkspaces: (state, action: PayloadAction<any[]>) => {
            state.workspaces = action.payload;
        },
        setChats: (state, action: PayloadAction<any[]>) => {
            state.chats = action.payload;
        },
        setFolders: (state, action: PayloadAction<any[]>) => {
            state.folders = action.payload;
        },
        setNotes: (state, action: PayloadAction<any[]>) => {
            state.notes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDialogs.fulfilled, (state, action: PayloadAction<DialogRecord[]>) => {
            const nextEntities = action.payload.map(record => ({
                id: record.uuid,
                type: WorkspaceEntityType.Chat,
                name: record.title,
                isStarred: false,
            }));
            state.chats = nextEntities;
            state.entities = nextEntities;
        })
        .addCase(createSpace.fulfilled, (state, action: PayloadAction<SpaceRecord>) => {
            state.entities = [{
                id: action.payload.uuid,
                type: WorkspaceEntityType.Folder,
                name: action.payload.name,
                isStarred: action.payload.isFavourite,
            }, ...state.entities];
        })
        .addCase(createChat.fulfilled, (state, action: PayloadAction<{ chatId: string }>) => {
            const newChat = {
                id: action.payload.chatId,
                type: WorkspaceEntityType.Chat,
                name: 'New chat',
                isStarred: false,
            };
            state.entities.push(newChat);
            state.chats.push(newChat);
        })
    }
});

export const selectEntities = createSelector(selectSelf, state => state.entities.entities);
export const selectStarredEntities = createSelector(selectSelf, state => state.entities.starredEntities);
export const selectChats = createSelector(selectSelf, state => state.entities.entities.filter(entity => entity.type === WorkspaceEntityType.Chat));
export const selectWorkspaces = createSelector(selectSelf, state => state.entities.workspaces);