import { Chat } from "./chat";
import { ChatMessage } from "./types";

import { useSelector } from "react-redux";
import { changeModel, clearHistory, selectChatMessages, selectInitialMessage, selectModels, selectSelectedModel, setInitialMessage } from "../../../state/slices/chat";
import { ModelSelect } from "../../components/modelSelect/modelSelect";
import { useAppDispatch } from "../../../state/store";
import { useStatedAutoRequest } from "../../../api/hooks/useStatedRequest";
import { fetchChatHistory } from "../../../state/thunks/chat/fetchChatHistory";
import { useParams } from "react-router";
import { getModelList } from "../../../state/thunks/chat/getModelList";
import { GenerativeModel } from "../../../types/models";
import { useSendMessage } from "../../components/useSendMessage";
import { useEffect, useState } from "react";


export const ChatContainer = () => {
    const { id: chatId } = useParams();
    useStatedAutoRequest(getModelList, {});
    const [chatIsLoaded, setChatLoaded] = useState<boolean>(false);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
    const { isLoading } = useStatedAutoRequest(fetchChatHistory, { chatId: chatId! }, {
        skip: !chatId
    });

    const messages = useSelector(selectChatMessages);
    const initialMessage = useSelector(selectInitialMessage);
    const models = useSelector(selectModels);
    const selectedModel = useSelector(selectSelectedModel);
    const dispatch = useAppDispatch();
    const sendMessage = useSendMessage();

    useEffect(() => {
        if (isLoading) {
            setChatLoaded(true);
            return;
        }

        if (initialMessage && !isLoading && chatIsLoaded) {
            setAwaitingResponse(true);
            const promise = sendMessage(initialMessage, chatId!);
            promise.then(() => setAwaitingResponse(false));
            dispatch(setInitialMessage());
        }
    }, [initialMessage, isLoading, chatIsLoaded]);

    useEffect(() => {
        return () => {
            dispatch(clearHistory());
        };
    }, []);

    const handleModelChange = (model: GenerativeModel) => {
        dispatch(changeModel(model));
    };

    const handleSendMessage = (message: string) => {
        setAwaitingResponse(true);
        const promise = sendMessage(message, chatId!);
        promise.then(() => {
            setAwaitingResponse(false);
        });
        return promise;
    }

    return (
        <>
            <ModelSelect models={models} selectedModel={selectedModel} onModelChange={handleModelChange} />
            <Chat messages={(messages || []) as ChatMessage[]} isLoading={isLoading} sendMessage={handleSendMessage} isWaiting={awaitingResponse} />
        </>
        
    );
}

export default ChatContainer;