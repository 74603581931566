import styled from "@emotion/styled";

export const Container = styled.div(() => ({
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    gap: 20,
}));

export const Prompts = styled.div(() => ({
    display: 'flex',
    flexWrap: 'wrap' as const,
    gap: 10,
    justifyContent: 'center',
    alignItems: 'stretch',
    button: {
        padding: '8px 14px',
    }
}));

export const Title = styled.div((props) => ({
    color: props.theme.colors.text.primary,
    opacity: 0.5,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 1.15,
}));