import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { decorateCapsule } from "./utils";
import { ServerCapsuleItem } from "./types";

export interface FetchCollectionsListResponse {
    items: ServerCapsuleItem[];
}

interface FetchCollectionsListArgs {
    showNested?: boolean;
}

export const action = 'collections/list'

export const fetchCollectionsList = createAsyncThunk(
    action,
    async (args: FetchCollectionsListArgs, thunkApi) => {
        const response = await callApi<FetchCollectionsListResponse>(
            rpcMethods.collections.list,
            { show_nested: args.showNested },
        );
        return {
            items: response.items.map(decorateCapsule),
        };
    },
)