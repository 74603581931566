import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { GenerativeModel } from "../../../types/models";
import openAiIcon from '../../../assets/images/openai-icon.svg';
import anthropicIcon from '../../../assets/images/anthropic-icon.svg';

export interface GetModelListResponse {
    models: string[];
}

interface GetModelListArgs {
}

export const action = 'chat/modelList'

const decorateModel = (key: string): GenerativeModel => {
    const [provider, name] = key.split('/');
    const iconMap: Record<string, string> = {
        'openai': openAiIcon,
        'anthropic': anthropicIcon,
    }
    
    return {
        key,
        name,
        icon: iconMap[provider],
    };
}

export const getModelList = createAsyncThunk(
    action,
    async (args: GetModelListArgs, thunkApi) => {
        const response = await callApi<GetModelListResponse>(rpcMethods.chat.modelList, args);
        return response.models.map(decorateModel);
    },
)