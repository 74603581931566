import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { ServerSpaceItem } from "./types";
import { decorateSpace } from "./utils";

export interface GetSpaceListResponse {
    data: ServerSpaceItem[];
}

interface GetSpaceListArgs {
}

export const action = 'spaces/list'

export const getSpaceList = createAsyncThunk(
    action,
    async (args: GetSpaceListArgs, thunkApi) => {
        const response = await callApi<GetSpaceListResponse>(
            rpcMethods.spaces.list,
            {},
        );

        return response.data.map(decorateSpace);
    },
)