import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatState } from "../types";
import { ChatMessage } from "../../types/chat";
import { selectSelf } from "./utils";
import { sendMessage } from "../thunks/chat/sendMessage";
import { fetchChatHistory } from "../thunks/chat/fetchChatHistory";
import { getModelList } from "../thunks/chat/getModelList";
import { GenerativeModel } from "../../types/models";
import Storage from "../../utils/storage";

const STORAGE_CAPSULE_KEY = 'ug-capsules';

const initialState: ChatState = {
    messages: [],
    initialMessage: undefined,
    nextMessage: '',
    selectedModel: null,
    capsules: Storage.get(STORAGE_CAPSULE_KEY) as unknown as string[] || [],
    models: [],
    visual: {
        codeBackground: true,
        codeTheme: Storage.get('ug-code-theme') || 'vs2015',
    },
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<string>) => {
            state.nextMessage = action.payload;
        },
        addMessage: (state, action: PayloadAction<ChatMessage>) => {
            state.messages.push(action.payload);
        },
        toggleCapsule: (state, action: PayloadAction<string>) => {
            if (state.capsules.includes(action.payload)) {
                state.capsules = [...state.capsules.filter(id => id !== action.payload)];
            } else {
                state.capsules.push(action.payload);
            }

            Storage.set(STORAGE_CAPSULE_KEY, state.capsules);
        },
        clearHistory: (state) => {
            state.messages = [];
        },
        setCodeTheme: (state, action: PayloadAction<string>) => {
            Storage.set('ug-code-theme', action.payload);
            state.visual.codeTheme = action.payload;
        },
        setCodeBackground: (state, action: PayloadAction<boolean>) => {
            state.visual.codeBackground = action.payload;
        },
        setInitialMessage: (state, action: PayloadAction<string | undefined>) => {
            state.initialMessage = action.payload;
        },
        changeModel: (state, action: PayloadAction<GenerativeModel>) => {
            state.selectedModel = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.messages.push(action.payload.response);
            })
            .addCase(fetchChatHistory.pending, (state, action) => {
                state.messages = [];
            })
            .addCase(fetchChatHistory.fulfilled, (state, action) => {
                const nextMessages = action.payload;
                state.messages = nextMessages;
            })
            .addCase(getModelList.fulfilled, (state, action) => {
                state.models = action.payload;
                state.selectedModel = action.payload[0];
            })
    }
});

export const {
    setCodeTheme,
    setCodeBackground,
    addMessage, setMessage, toggleCapsule, changeModel, setInitialMessage, clearHistory
} = chatSlice.actions;
export const selectChatMessages = createSelector(selectSelf, state => state.chat.messages);
export const selectInitialMessage = createSelector(selectSelf, state => state.chat.initialMessage);
export const selectModels = createSelector(selectSelf, state => state.chat.models);
export const selectSelectedModel = createSelector(selectSelf, state => state.chat.selectedModel);
export const selectEnabledCapsules = createSelector(selectSelf, state => state.chat.capsules);
export const selectVisualSettings = createSelector(selectSelf, state => state.chat.visual);