import styled from '@emotion/styled';
import { Button } from '../../../components/buttons';
import { Link } from 'react-router-dom';
import { Svg } from 'components/icons/styles';

export const Container = styled.div((props) => ({
    gridColumn: 'navigation-start / navigation-end',
    gridRow: 'header-start / end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    borderRight: `1px solid rgba(217, 217, 217, 0.10)`,
    backdropFilter: 'blur(15px)',
}));

export const Header = styled.div(() => ({
    display: 'flex',
    padding: '23px 20px 26px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridRow: 'header-start / header-end',
    gridColumn: 'navigation-start / navigation-end',
    borderBottom: `1px solid rgba(217, 217, 217, 0.10)`,
}));

export const Logo = styled.img(() => ({
    width: 90,
    height: 21,
}));

export const Section = styled.div(() => ({
    padding: 18,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: `1px solid rgba(217, 217, 217, 0.10)`,
}));

export const SectionTitle = styled.h5((props) => ({
    color: props.theme.colors.text.primary,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.1,
    opacity: 0.5,
    margin: 0,
    marginBottom: 18,
}));

export const Workspaces = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
}));

export const Version = styled.span((props) => ({
    color: props.theme.colors.text.primary,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
}));

export const SiteLink = styled(Link)((props) => ({
    textDecoration: 'none',
    color: props.theme.colors.text.secondary,
    display: 'inline-flex',
    gap: 8,
}));

export const LogoutButton = styled(Button)(() => ({}));

export const Delimeter = styled.div((props) => ({
    borderBottom: `1px solid ${props.theme.colors.line}`,
}));

export const PoweredByContainer = styled.div(() => ({}));

export const PoweredBy = styled.span((props) => ({
    color: props.theme.colors.text.dark,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
}));

export const PoweredByLink = styled.a((props) => ({
    color: props.theme.colors.accent,
    textDecoration: 'none',
}));

export const BackButton = styled.span((props) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    color: props.theme.colors.text.primary,
    [`${Svg}`]: {
        transform: 'rotate(-90deg)',
    },
}));