import styled from '@emotion/styled';

export const Container = styled.div((props) => ({
    backgroundColor: 'transparent',
    gridColumn: 'navigation-end / sidebar-start',
    gridRow: 'header-start / end',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    gap: 20,
    alignItems: 'stretch',
}));