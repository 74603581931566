import { ModelPrompt } from "../../types/app";

export const prompts: ModelPrompt[] = [{
    id: '1',
    name: 'Prepare my new post for instagram',
    content: '',
}, {
    id: '2',
    name: 'Help me start learning Python',
    content: '',
}, {
    id: '3',
    name: 'How to explain game theory for my kid',
    content: '',
}, {
    id: '4',
    name: 'Proofread my article for Science Magazine',
    content: '',
}, {
    id: '5',
    name: 'Debug my JS script',
    content: '',
}]