import styled from '@emotion/styled';
import { Link as ReactLink } from "react-router-dom";

import { Path } from '../../../../../components/icons/styles';

export const ItemsList = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    gap: 12,
    maxHeight: 'calc(100vh - 350px)',
    overflowY: 'auto' as const,
}));

export const Item = styled.button(() => ({
    paddingLeft: 16,
    width: '100%',
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
}));

export const ItemIcon = styled.div((props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    height: 14,
    //@ts-ignore
    [Path]: {
        stroke: props.theme.colors.text.primary,
    }
}));

export const StarButton = styled.button((props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    border: 0,
    margin: 0,
    padding: 2,
    backgroundColor: 'transparent',
    cursor: 'pointer',
}));

export const ItemContent = styled.span((props) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 10,
    fontSize: 12,
    lineHeight: 1.2,
    textAlign: 'left',
    color: props.theme.colors.text.primary,
}));

export const ItemInfo = styled(ReactLink)((props) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 10,
    fontSize: 12,
    lineHeight: 1.2,
    textAlign: 'left',
    color: props.theme.colors.text.primary,
    textDecoration: 'unset',
}));

export const Name = styled.span((props) => ({
    textDecoration: 'unset',
    color: props.theme.colors.text.primary,
}));

export const Buttons = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    alignItems: 'center',
}))

export const RemoveButton = styled.button((props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    border: 0,
    margin: 0,
    padding: 2,
    backgroundColor: 'transparent',
    cursor: 'pointer',
}));