import { useEffect, useRef } from "react";
import { MessageInput } from "../../../components/inputs/messageInput/messageInput";
import { ModelMessage } from "../../../components/chat/modelMessage";
import { Bumper, ChatMessages, Container, Scrollable } from "./styles";
import { ChatMessage } from "./types";
import { LoadingIndicator } from "../../../components/loading/indicator";

interface ChatProps {
    messages: ChatMessage[];
    sendMessage: (message: string) => Promise<boolean>;
    isLoading: boolean;
    isWaiting: boolean;
}

export const Chat = ({ messages, sendMessage, isLoading, isWaiting }: ChatProps) => {
    const messagesRef = useRef<HTMLDivElement>();
    const handleMessageRef = (ref: HTMLDivElement) => {
        messagesRef.current = ref;
    }

    useEffect(() => {
        messagesRef.current?.scrollIntoView();
    }, [messages.length, isLoading]);

    if (isLoading) {
        return (
            <Container>
                <LoadingIndicator />
                <MessageInput sendMessage={sendMessage} disabled />
            </Container>
        );
    }

    return (
        <Container>
            <ChatMessages>
                <Scrollable >
                    <Bumper />
                    {messages.map(message => <ModelMessage key={message.uuid} {...message} />)}
                    <Bumper ref={handleMessageRef}/>
                </Scrollable>
            </ChatMessages>
            <MessageInput sendMessage={sendMessage} isWaiting={isWaiting} />
        </Container>
    );
}