import { useTranslation } from "react-i18next";
import { Section } from "../styles";
import { ActionButton, ActionButtonContent, ActionIcon, Actions } from "./styles";
import { LABELS } from "./keys";
import { IconConversation } from "../../../../../components/icons/conversation";
import { IconNote } from "../../../../../components/icons/note";
import { IconWorkspace } from "../../../../../components/icons/workspace";

interface ActionsSectionProps {
    createChat: () => void;
    createNote: () => void;
    createWorkspace: () => void;
    createSpace: () => void;
}

export const ActionsSection = ({ createChat, createNote, createWorkspace, createSpace }: ActionsSectionProps) => {
    const { t } = useTranslation();
    return (
        <Section>
            <Actions>
                <ActionButton primary onClick={createChat}>
                    <ActionButtonContent>
                        <ActionIcon><IconConversation /></ActionIcon>
                        {t(LABELS.CREATE_CHAT)}
                    </ActionButtonContent>
                </ActionButton>
                <ActionButton primary onClick={createSpace}>
                    <ActionButtonContent>
                        <ActionIcon><IconWorkspace /></ActionIcon>
                        {t(LABELS.CREATE_SPACE)}
                    </ActionButtonContent>
                </ActionButton>
                {/* <ActionButton primary onClick={createNote}>
                    <ActionButtonContent>
                        <ActionIcon><IconNote /></ActionIcon>
                        {t(LABELS.CREATE_NOTE)}
                    </ActionButtonContent>
                </ActionButton>
                <ActionButton primary onClick={createWorkspace}>
                    <ActionButtonContent>
                        <ActionIcon><IconWorkspace /></ActionIcon>
                        {t(LABELS.CREATE_WORKSPACE)}
                    </ActionButtonContent>
                </ActionButton> */}
            </Actions>
        </Section>
    );
}