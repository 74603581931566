import { Container } from "./styles";
import { WorkspaceEntity } from "../../../types/app";
import { EntityList } from "../../../components/entityList/list";

interface ChatListProps {
    chats: WorkspaceEntity[];
    openChat: (item: WorkspaceEntity) => void;
}

export const ChatList = ({ chats, openChat }: ChatListProps) => {
    return (
        <Container>
            <EntityList items={chats} onItemClick={openChat} />
        </Container>
    );
}