import { rpcMethods } from "../endpoints";
import { capsuleListResponse } from "./capsules";
import { chatMessages } from "./chatMessages";
import { entities } from "./entities";
import { prompts } from "./prompts";
import { workspaceInfoResponse } from "./workspace";

export const responseMock: Record<string, any> = {
    [rpcMethods.chat.history]: chatMessages,
    [rpcMethods.entities]: { items: entities },
    [rpcMethods.prompts]: prompts,
    [rpcMethods.capsules]: capsuleListResponse,
    [rpcMethods.workspaceInfo]: workspaceInfoResponse,
}