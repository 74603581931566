import styled from "@emotion/styled/macro";
import { Button } from "../../buttons";
import { Path } from "../../icons/styles";
import Markdown from "react-markdown";

export const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'stretch',
    boxSizing: 'border-box' as const,
    gap: 20,
    padding: '20px 20px 16px 20px',
    borderRadius: 24,
    background: `radial-gradient(99.21% 128.34% at 7.27% 11.27%, rgba(33, 37, 45, 0.75) 0%, rgba(22, 25, 29, 0.75) 100%)`,
}));

export const Header = styled.div(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const Controls = styled.div(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 6,
}));

export const ControlButton = styled(Button)((props) => ({
    border: '1px solid rgba(217, 217, 217, 0.10)',
    borderRadius: 30,
    width: 25,
    height: 25,
    padding: 0,
    transition: 'all .3s ease',
    //@ts-ignore
    [Path]: {
        transition: 'all .3s ease',
        opacity: 0.5,
    },
    '&:hover': {
        border: '1px solid rgba(217, 217, 217, 1)',
        //@ts-ignore
        [Path]: {
            opacity: 1,
        }
    }
}));

export const UserCard = styled.div(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
}));

export const UserInfo = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
}));

export const UserImage = styled.img(() => ({
    border: '1px solid rgba(217, 217, 217, 0.10)',
    width: 30,
    height: 30,
    borderRadius: 30,
}));

export const UserName = styled.span((props) => ({
    color: props.theme.colors.text.primary,
    fontSize: 12,
    lineHeight: 1.25,
}));

export const UserHandle = styled.span((props) => ({
    color: props.theme.colors.text.primary,
    fontSize: 12,
    lineHeight: 1.25,
    opacity: 0.5,
}));

export const Footer = styled.div(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
}));

export const TokenInfo = styled.span((props) => ({
    color: props.theme.colors.text.primary,
    fontSize: 12,
    lineHeight: 1.1,
    opacity: 0.25,
}));

export const Body = styled.div(() => ({

}));

export const Message = styled(Markdown)((props) => ({
    color: props.theme.colors.text.primary,
    fontSize: 16,
    lineHeight: 1.6,
    margin: 0,
    textAlign: 'left',
    width: '100%',
}));