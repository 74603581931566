import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { ServerSpaceItem } from "./types";
import { decorateSpace } from "./utils";

export interface AddSpaceResponse {
    data: ServerSpaceItem;
}

interface AddSpaceArgs {
    name: string;
    description?: string;
}

export const action = 'spaces/add'

export const createSpace = createAsyncThunk(
    action,
    async (args: AddSpaceArgs, thunkApi) => {
        // const response = await callApi<AddSpaceResponse>(
        //     rpcMethods.spaces.create,
        //     { name: args.name, description: args.description },
        // );

        // return decorateSpace(response.data);
        return decorateSpace({
            uuid: Math.random().toString(),
            name: args.name,
            description: args.description,
            client_id: "",
            organization_id: "",
            is_default: false,
            is_favourite: false,
            created_at: "",
            updated_at: "",
            deleted_at: ""
        })
    },
)