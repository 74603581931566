import { useTranslation } from "react-i18next";
import { Section } from "../styles";
import { ActionButton, ActionIcon, ActionLink, Actions } from "./styles";
import { LABELS } from "./keys";
import { IconGrid } from "../../../../../components/icons/grid";
import { IconClock } from "../../../../../components/icons/clock";
import { routePath } from "../../../../../router/path";

interface FilesSectionProps {
    createChat: () => void;
    createNote: () => void;
    createWorkspace: () => void;
}

export const FilesSection = ({ createChat, createNote, createWorkspace }: FilesSectionProps) => {
    const { t } = useTranslation();
    return (
        <Section>
            <Actions>
                <ActionButton onClick={createChat}>
                    <ActionLink to={routePath.root}>
                        <ActionIcon><IconGrid width={12} height={12} /></ActionIcon>
                        {t(LABELS.ALL_FILES)}
                    </ActionLink>
                </ActionButton>
                {/* <ActionButton onClick={createNote}>
                    <ActionLink to={routePath.recent}>
                        <ActionIcon><IconClock width={12} height={12} /></ActionIcon>
                        {t(LABELS.RECENT_FILES)}
                    </ActionLink>
                </ActionButton> */}
            </Actions>
        </Section>
    );
}