import { Outlet } from 'react-router';
import { Container } from './styles';

interface MainProps {

};

export const Main = ({ }: MainProps) => {
    return (
        <Container>
            <Outlet />
        </Container>
    );
}