import React from 'react';

import Main from './blocks/main/container';
import Sidebar from './blocks/sidebar/container';
import Navigation from './blocks/navigation/container';
import Header from './blocks/header/container';
import { SettingsNavigationContainer as SettingsNavigation } from './blocks/navigation/container';
import { Container } from './styles';

export const DesktopLayout = () => {
    return (
        <Container>
            <Main />
            <Header />
            <Navigation />
            <Sidebar />
        </Container>
    )
}

export const SettingsDesktopLayout = () => {
    return (
        <Container>
            <Main />
            <Header />
            <SettingsNavigation />
        </Container>
    )
}