import styled from '@emotion/styled';
import { Path } from '../../../../../components/icons/styles';
import { Link } from 'react-router-dom';

export const Actions = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 12,
}));

export const ActionButton = styled.button(() => ({
    paddingLeft: 16,
    width: '100%',
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
}));

export const ActionIcon = styled.div((props) => ({
    height: 12,
    //@ts-ignore
    [Path]: {
        stroke: props.theme.colors.text.primary,
    }
}));

export const ActionLink = styled(Link)((props) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'flex-start',
    gap: 10,
    fontSize: 12,
    lineHeight: 1.2,
    color: props.theme.colors.text.primary,
}));