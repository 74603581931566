import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { decorateMessage } from "./utils";
import { RawMessage } from "./types";
import { GenerativeModel } from "../../../types/models";

export interface SendMessageResponse {
    chatId: string;
    request: RawMessage;
    response: RawMessage;
}

interface SendMessageThunkArgs {
    chatId: string;
    message: string;
    model: GenerativeModel;
    settings?: {
        capsuleIds?: string[];
    };
}

export const action = 'chat/sendMessage'

export const sendMessage = createAsyncThunk(
    action,
    async (args: SendMessageThunkArgs, thunkApi) => {
        const apiResponse = await callApi<SendMessageResponse>(rpcMethods.chat.sendMessage, {
            chat_id: args.chatId,
            message: args.message,
            model: args.model.key,
            collection_ids: args.settings?.capsuleIds,
        });

        const { request, response } = apiResponse;
        return {
            request: decorateMessage(request),
            response: decorateMessage(response),
        };
    },
)