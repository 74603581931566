import { useSelector } from "react-redux";
import { ItemsSection } from "./items"
import { selectChats } from "../../../../../state/slices/entities";
import { useStatedAutoRequest } from "../../../../../api/hooks/useStatedRequest";
import { fetchDialogs } from "../../../../../state/thunks/chat/fetchDialogs";

export const ItemsSectionContainer = () => {
    const chats = useSelector(selectChats);
    const { isLoading } = useStatedAutoRequest(fetchDialogs, {});

    return <ItemsSection items={chats} isLoading={isLoading} />;
}