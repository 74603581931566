import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconFolder = ({
    width = 14,
    height = 13,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 13" fill="none">
        <Path d="M13 5.59012V8.65012C13 10.6901 12.4 11.2001 10 11.2001H4C1.6 11.2001 1 10.6901 1 8.65012V3.55012C1 1.51012 1.6 1.00012 4 1.00012H4.9C5.8 1.00012 5.998 1.22452 6.34 1.61212L7.24 2.63212C7.468 2.88712 7.6 3.04012 8.2 3.04012H10C12.4 3.04012 13 3.55012 13 5.59012Z" strokeMiterlimit="10"/>
    </Svg>
)