import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconNote = ({
    width = 18,
    height = 18,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
        <Path d="M17 7.4V11.4C17 15.4 15.4 17 11.4 17H6.6C2.6 17 1 15.4 1 11.4V6.6C1 2.6 2.6 1 6.6 1H10.6" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M17 7.4H13.8C11.4 7.4 10.6 6.6 10.6 4.2V1L17 7.4Z" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)