import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconGear = ({
    width = 14,
    height = 14,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
        <Path d="M6.99698 8.88043C7.99059 8.88043 8.79608 8.07494 8.79608 7.08133C8.79608 6.08771 7.99059 5.28223 6.99698 5.28223C6.00336 5.28223 5.19788 6.08771 5.19788 7.08133C5.19788 8.07494 6.00336 8.88043 6.99698 8.88043Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M1 7.60908V6.55361C1 5.92992 1.50975 5.41418 2.13943 5.41418C3.22489 5.41418 3.66867 4.64657 3.12294 3.70504C2.81109 3.16531 2.997 2.46366 3.54273 2.15181L4.58021 1.55811C5.05397 1.27625 5.66567 1.44417 5.94753 1.91793L6.01349 2.03187C6.55322 2.9734 7.44078 2.9734 7.98651 2.03187L8.05247 1.91793C8.33433 1.44417 8.94603 1.27625 9.41979 1.55811L10.4573 2.15181C11.003 2.46366 11.1889 3.16531 10.8771 3.70504C10.3313 4.64657 10.7751 5.41418 11.8606 5.41418C12.4843 5.41418 13 5.92393 13 6.55361V7.60908C13 8.23277 12.4903 8.74851 11.8606 8.74851C10.7751 8.74851 10.3313 9.51613 10.8771 10.4577C11.1889 11.0034 11.003 11.699 10.4573 12.0109L9.41979 12.6046C8.94603 12.8864 8.33433 12.7185 8.05247 12.2448L7.98651 12.1308C7.44678 11.1893 6.55922 11.1893 6.01349 12.1308L5.94753 12.2448C5.66567 12.7185 5.05397 12.8864 4.58021 12.6046L3.54273 12.0109C2.997 11.699 2.81109 10.9974 3.12294 10.4577C3.66867 9.51613 3.22489 8.74851 2.13943 8.74851C1.50975 8.74851 1 8.23277 1 7.60908Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)