import styled from "@emotion/styled";
import { Button } from "components/buttons";

export const List = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    gap: 10,
}));

export const MenuItem = styled(Button)<{ active?: boolean }>(({ active }) => ({
    border: 0,
    justifyContent: 'flex-start',
    opacity: active ? 1 : 0.5,
}));