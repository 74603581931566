import styled from "@emotion/styled/macro";
import { Button } from "../../buttons";
import { InputRoot } from "../input/styles";

export const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 660,
    padding: 20,
    gap: 15,
    boxSizing: 'border-box' as const,
    position: 'absolute' as const,
    bottom: 0,
}));

export const InputContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
}));

export const SendButton = styled(Button)((props) => ({
    marginRight: -10,
    marginTop: -10,
    marginBottom: -10,
    height: 48,
    padding: '0 15px 0 12px',
    borderRadius: '0 10px 10px 0',
    border: 0,
    borderLeft: '1px solid rgba(217, 217, 217, 0.10)',
}));

export const MessageHint = styled.div((props) => ({
    color: props.theme.colors.text.primary,
    fontSize: 12,
    lineHeight: 1.25,
    fontWeight: 300,
    opacity: 0.25,
}));

export const InputWrapper = styled.div((props) => ({
    flex: 1,

    // @ts-ignore
    [InputRoot]: {
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 16,
        border: '2px solid #B784F9',
        background: 'linear-gradient(90deg, #666 0%, #555 100%)',
        boxShadow: '0px 0px 20px 0px rgba(183, 132, 249, 0.50)',
    },
    input: {
        color: props.theme.colors.text.primary,
        flex: 1,
    }
}));