import styled from '@emotion/styled/macro';

export const InputRoot = styled.div((props) => ({
    display: 'inline-flex',
    border: `1px solid ${props.theme.colors.background.primary}`,
    boxShadow: '2px 2px 4px 0px rgba(29, 29, 29, 0.4) inset',
    '&.base--focused': {
        border: `1px solid ${props.theme.colors.text.primary}`,
    },
    borderRadius: 8,
    padding: 8,
    minWidth: 0,
}));

export const Input = styled.input((props) => ({
    background: 'transparent',
    border: 0,
    outline: 'none',
    color: props.theme.colors.text.primary, 
    minWidth: 0,
}))

export const Textarea = styled.textarea((props) => ({
    background: 'transparent',
    border: 0,
    outline: 'none',
    width: '100%',
    color: props.theme.colors.text.primary, 
    minWidth: 0,
}))