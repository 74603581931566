import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconCapsule = ({
    width = 14,
    height = 14,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
        <Path d="M11.5976 2.40947C14.1778 4.9945 14.1323 9.21387 11.4676 11.7468C9.00426 14.0844 5.00711 14.0844 2.53732 11.7468C-0.133956 9.21387 -0.17946 4.9945 2.40732 2.40947C4.94211 -0.13649 9.06277 -0.13649 11.5976 2.40947Z" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M9.49813 9.63336C8.12024 10.9333 5.88445 10.9333 4.51306 9.63336" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)