import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/buttons";
import { Modal } from "../../../../components/modal";
import { ModalContainer, ModalHeader, ModalPanel } from "../../../../components/modal/styles";
import { LABELS } from "./keys";
import { Fields, NameLabel } from "./styles";
import { Input } from "../../../../components/inputs/input";
import { FileDropzone } from "../../../../components/inputs/fileDropzone";
import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import uniqBy from 'lodash/uniqBy';
import { CapsuleItem, FileRecord } from "../../../../types/app";
import { KeyboardKeys } from "../../../../constants/keyboard";
import { FilesSection } from "./filesSection";

export interface CapsuleControls {
    onCreateCapsule: (name: string) => Promise<any>;
    onRenameCapsule: (capsuleId: string, name: string) => Promise<any>;
    onUploadFile: (
        file: File,
        capsuleId: string,
        handlers: {
            onProgress: (progress: number) => void;
        },
    ) => Promise<any>;
    onDeleteFile: (id: string) => Promise<any>;
    onDeleteCapsule: (id: string) => Promise<any>;
    toggleCapsuleState: (capsuleId: string) => void;
    getCapsuleFiles: (capsuleId: string) => Promise<any>;
    isCreatingCapsule: boolean;
    isRenamingCapsule: boolean;
    isFetchingFiles: boolean;
}

interface AddCapsuleModalProps {
    open: boolean;
    capsule?: CapsuleItem;
    onClose: () => void;
    capsuleControls: CapsuleControls;
}

export const AddCapsuleModal = ({ open, onClose, capsuleControls, capsule }: AddCapsuleModalProps) => {
    const { 
        onCreateCapsule,
        onRenameCapsule,
        onUploadFile,
        onDeleteFile,
        getCapsuleFiles,
    } = capsuleControls;
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [name, setName] = useState<string>(capsule?.name || '');
    const [fileProgress, setFileProgress] = useState<Record<string, number>>({});
    const [filePromises, setFilePromises] = useState<Record<string, Promise<any>>>({});
    const [items, setItems] = useState<FileRecord[]>([]);
    const { t } = useTranslation();

    const fetchCapsuleItems = async (capsule?: CapsuleItem) => {
        if (!capsule) {
            return;
        }

        const response = await getCapsuleFiles(capsule.id);
        setItems(response.payload || []);
    }

    const handleProgress = (fileName: string) => (progress: number) => {
        console.log(fileName, progress);
        setFileProgress(currentProgress => ({
            ...currentProgress,
            [fileName]: progress,
        }));
    }
   
    useEffect(() => {
        setName(capsule?.name || '');
        fetchCapsuleItems(capsule);    
    }, [capsule]);

    const handleChangeFiles = async (files: File[]) => {
        const nextFiles = uniqBy([...acceptedFiles, ...files], 'path');
        setAcceptedFiles(nextFiles);
        const promises: Record<string, Promise<any>> = {};
        if (capsule) {
            nextFiles.forEach(async (file) => {
                promises[file.name] = onUploadFile(file, capsule?.id, { onProgress: handleProgress(file.name) });
            });
        }

        setFilePromises(promises);
        await Promise.allSettled(Object.values(promises));
        fetchCapsuleItems(capsule);
        setAcceptedFiles([]);
        setFileProgress({});
    };

    const handleCancelUpload = (item: File) => () => {
        if (fileProgress[item.name] === 100) {
            return;
        }
        // @ts-ignore
        filePromises[item.name]?.abort?.();
        setAcceptedFiles(currentFiles => currentFiles.filter(file => item.name !== file.name));
    }

    const handleClose = () => {
        setItems([]);
        onClose();
    }

    const handleSubmit = async () => {
        if (!capsule) {
            await onCreateCapsule(name);
            return;    
        }

        await onRenameCapsule(capsule.id, name);
    };

    const handleRemove = (file: FileRecord) => async () => {
        const result = await onDeleteFile(file.id);
        const nextItems = items.filter(item => item.id !== file.id);
        setItems(nextItems);
    };

    const handleNameKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardKeys.Enter) {
            handleSubmit();
        }
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const isSubmitDisabled = capsule
        ? Boolean(!name || name === capsule.name || capsuleControls.isRenamingCapsule)
        : Boolean(!name);

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalContainer>
                <Button flat onClick={handleClose}>
                    {t(LABELS.CLOSE_BUTTON_TITLE)}
                </Button>
                <ModalPanel>
                    <ModalHeader>
                        {t(capsule ? LABELS.EDIT_TITLE : LABELS.TITLE)}
                    </ModalHeader>
                    <Fields>
                        <NameLabel>{t(LABELS.NAME_LABEL)}</NameLabel>
                        <Input
                            fullWidth
                            placeholder={t(LABELS.NAME_PLACEHOLDER)}
                            value={name}
                            onChange={handleNameChange}
                            onKeyDown={handleNameKeyDown}
                        />
                        {
                            capsule && (
                                <Button secondary disabled={isSubmitDisabled} onClick={handleSubmit}>
                                    {t(LABELS.CHANGE_NAME_LABEL)}
                                </Button>
                            )
                        }
                        {
                            capsule && (<FileDropzone onChangeFiles={handleChangeFiles}/>)
                        }
                    </Fields>
                    <FilesSection
                        acceptedFiles={acceptedFiles}
                        items={items}
                        fileProgress={fileProgress}
                        onCancelUpload={handleCancelUpload}
                        onRemove={handleRemove}
                        isFetching={capsuleControls.isFetchingFiles}
                    />
                    {
                        !capsule && (
                            <Button secondary disabled={isSubmitDisabled} onClick={handleSubmit}>
                                {t(LABELS.SUBMIT_LABEL)}
                            </Button>
                        )
                    }
                </ModalPanel>
            </ModalContainer>
        </Modal>
    );
}