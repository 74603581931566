import styled from '@emotion/styled';
import { Button } from '../../../../../components/buttons';
import { Path } from '../../../../../components/icons/styles';

export const Actions = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
}));

export const ActionButton = styled(Button)(() => ({
    padding: '0 16px',
    width: '100%',
}));

export const ActionIcon = styled.div((props) => ({
    height: 16,
    //@ts-ignore
    [Path]: {
        stroke: props.theme.colors.text.primary,
    }
}));

export const ActionButtonContent = styled.span(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
}));