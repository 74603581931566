import styled from "@emotion/styled";

export const Container = styled.div(() => ({
    gap: 10,
    display: 'flex',
    flexDirection: 'column' as const,
}));

export const Dropzone = styled.div(() => ({
    borderRadius: 10,
    height: 159,
    width: '100%',
    border: '1px solid rgba(255, 255, 255, 0.25)',
    background: 'radial-gradient(73.2% 65.09% at 50% 50%, #040509 66%, #120C1C 100%)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
}));

export const Placeholder = styled.p((props) => ({
    color: props.theme.colors.text.primary,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.1,
}));

export const Link = styled.a(() => ({
    textDecoration: 'underline',
}))

export const SupportedFormats = styled.label((props) => ({
    color: props.theme.colors.text.primary,
    opacity: 0.3,
}))