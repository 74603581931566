import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../../../api"
import { apiEndpoints } from "../../../api/endpoints";
import { HTTPMethod } from "../../../api/types";
import { RawUserRecord } from "./types";
import { UserInfo } from "types/user";

export interface GetUserInfoResponse {
    user: RawUserRecord;
}

interface GetUserInfoArgs {
}

export const action = 'auth/userInfo'

const decorateUserRecord = (record: RawUserRecord): UserInfo => ({
    id: record.id,
    name: record.display_name,
    handle: record.email,
    email: record.email,
    lastActive: record.updated_at,
});

export const getUserInfo = createAsyncThunk(
    action,
    async (args: GetUserInfoArgs, thunkApi) => {
        const response = await fetchData<GetUserInfoResponse>(
            apiEndpoints.auth.userInfo,
            HTTPMethod.POST,
            '{}',
        );

        return decorateUserRecord(response.user);
    },
)