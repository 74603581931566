import { useTranslation } from "react-i18next";
import { Button } from "../../components/buttons";
import { Body, Buttons, Container, Fields, Header, LoginButton, Logo, LogoutButton, Panel } from "./styles";
import { LABELS } from "./keys";
import { Input } from "../../components/inputs/input";
import { ChangeEvent, useState } from "react";
import upgraideLogo from '../../assets/images/upgraide-logo.svg';

interface LoginPageProps {
    onLogin: (login: string, password: string) => void;
    login: string;
    password: string;
    setLogin: (login: string) => void;
    setPassword: (password: string) => void;
    isLoading: boolean;
}

enum PageMode {
    Login = 'login',
    ForgotPassword = 'forgotPassword',
}

export const LoginPage = ({ onLogin, login, password, setLogin, setPassword, isLoading }: LoginPageProps) => {
    const [mode, setMode] = useState<PageMode>(PageMode.Login);
    const { t } = useTranslation();

    const handleChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value);
    };
    
    const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleLogin = () => {
        onLogin(login, password);
    }

    const toggleMode = () => {
        setMode(mode === PageMode.ForgotPassword ? PageMode.Login : PageMode.ForgotPassword);
    };

    if (mode === PageMode.Login) {
        return (
            <Container>
                {/* <Logo src={upgraideLogo} /> */}
                <Panel>
                    <Header>{t(LABELS.TITLE)}</Header>
                    <Body>
                        <Fields>
                            <Input value={login} onChange={handleChangeLogin} placeholder={t(LABELS.LOGIN_PLACEHOLDER)} />
                            <Input value={password} onChange={handleChangePassword} type="password" placeholder={t(LABELS.PASSWORD_PLACEHOLDER)} />
                        </Fields>
                        <Buttons>
                            <LoginButton secondary onClick={handleLogin} disabled={isLoading}>
                                {t(LABELS.LOGIN_BUTTON_LABEL)}
                            </LoginButton>
                        </Buttons>
                        <LogoutButton flat onClick={toggleMode}>
                            {t(LABELS.RESET_BUTTON_LABEL)}
                        </LogoutButton>
                    </Body>
                </Panel>
            </Container>
        )
    }

    return (
        <Container>
            {/* <Logo src={upgraideLogo} /> */}
            <Panel>
                <Header>{t(LABELS.RESET_TITLE)}</Header>
                <Body>
                    <Fields>
                        <Input value={login} onChange={handleChangeLogin} placeholder={t(LABELS.EMAIL_PLACEHOLDER)} />
                    </Fields>
                    <Buttons>
                        <LoginButton secondary onClick={handleLogin} disabled={isLoading}>
                            {t(LABELS.RESET_BUTTON_LABEL)}
                        </LoginButton>
                    </Buttons>
                    <LogoutButton flat onClick={toggleMode}>
                        {t(LABELS.LOGIN_BUTTON_LABEL)}
                    </LogoutButton>
                </Body>
            </Panel>
        </Container>
    );
}