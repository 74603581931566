import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntegrationsState } from "../types";
import { selectSelf } from "./utils";
import { Integration } from "types/app";
import { fetchIntegrationList } from "state/thunks/integrations/fetchIntegrationList";
import { addIntegration } from "state/thunks/integrations/addIntegration";
import { removeIntegration } from "state/thunks/integrations/removeIntegration";

const initialState: IntegrationsState = {
    integrations: [],
    selectedIntegration: null,
}

export const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        selectIntegration: (state: IntegrationsState, action: PayloadAction<Integration>) => {
            state.selectedIntegration = action.payload;
        },
        deselectIntegration: (state: IntegrationsState, action: PayloadAction<undefined>) => {
            state.selectedIntegration = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationList.fulfilled, (state, action) => {
                state.integrations = action.payload;
            })
            .addCase(addIntegration.fulfilled, (state, action) => {
                state.integrations.push(action.payload);
            })
            .addCase(removeIntegration.fulfilled, (state, action) => {
                state.integrations = state.integrations.filter(integration => integration.id !== action.payload.uuid);
            })
    }
});

export const {
    selectIntegration,
    deselectIntegration,
} = integrationsSlice.actions;

export const selectIntegrations = createSelector(selectSelf, state => state.integrations.integrations);
export const selectSelectedIntegration = createSelector(selectSelf, state => state.integrations.selectedIntegration);