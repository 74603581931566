import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";

export interface RemoveIntegrationResponse {
    uuid: string;
    is_success: boolean;
}

interface RemoveIntegrationArgs {
    id: string;
}

export const action = 'integration/remove'

export const removeIntegration = createAsyncThunk(
    action,
    async (args: RemoveIntegrationArgs, thunkApi) => {
        const response = await callApi<RemoveIntegrationResponse>(rpcMethods.integrations.remove, {
            uuid: args.id,
        });
        return response;
    },
)