import React from 'react';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react';

import { store } from './state/store'
import { darkTheme } from './theme';

import './providers/localisation/i18n';
import { MainRouter } from './router';
import { AuthController } from './providers/auth';
import { NotificationProvider } from './providers/notifications/notificationProvider';


function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <NotificationProvider />
        <AuthController />
        <MainRouter />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
