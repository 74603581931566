import styled from "@emotion/styled";
import { MaterialDesignContent } from "notistack";

export const StyledNotification = styled(MaterialDesignContent)((props) => ({
    backdropFilter: 'blur(10px)',
    borderRadius: 14,
    backgroundColor: 'transparent',
    border: `1px solid ${props.theme.colors.accent}`,
    justifyContent: 'center',
    svg: {
        display: 'none',
    },
    '&.notistack-MuiContent-success': {
        borderColor: props.theme.colors.event.success,
    },
    '&.notistack-MuiContent-error': {
        borderColor: props.theme.colors.event.error,
    },
    '&.notistack-MuiContent-warning': {
        borderColor: props.theme.colors.event.warning,
    },
    '&.notistack-MuiContent-info': {
        borderColor: props.theme.colors.third,
    },
}));