import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconList = ({
    width = 16,
    height = 15,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 15" fill="none">
        <Path d="M1.4375 1.375H14.5625" strokeLinecap="round"/>
        <Path d="M1.4375 7.5H14.5625" strokeLinecap="round"/>
        <Path d="M1.4375 13.625H14.5625" strokeLinecap="round"/>
    </Svg>
)