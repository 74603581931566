import { makeLabels } from "utils/labels";
import { SettingsMenuItem } from "./types";
import { routePath } from "router/path";

const prefix = 'blocks.navigation.settings';
export const LABELS = makeLabels(prefix, {
    CHAT_SETTINGS: 'chatSettings',
    INTEGRATION_SETTINGS: 'integrationSettings',
    BACK_BUTTON_LABEL: 'backButtonLabel',
    TITLE: 'title',
});

export const navigationItems: SettingsMenuItem[] = [{
    route: routePath.settings.chat,
    label: LABELS.CHAT_SETTINGS,
}, {
    route: routePath.settings.integrations,
    label: LABELS.INTEGRATION_SETTINGS,
}];