import { makeLabels } from "../../../utils/labels"

export const options = [
    'a11yDark',
    'a11yLight',
    'agate',
    'anOldHope',
    'androidstudio',
    'arduinoLight',
    'arta',
    'ascetic',
    'atelierCaveDark',
    'atelierCaveLight',
    'atelierDuneDark',
    'atelierDuneLight',
    'atelierEstuaryDark',
    'atelierEstuaryLight',
    'atelierForestDark',
    'atelierForestLight',
    'atelierHeathDark',
    'atelierHeathLight',
    'atelierLakesideDark',
    'atelierLakesideLight',
    'atelierPlateauDark',
    'atelierPlateauLight',
    'atelierSavannaDark',
    'atelierSavannaLight',
    'atelierSeasideDark',
    'atelierSeasideLight',
    'atelierSulphurpoolDark',
    'atelierSulphurpoolLight',
    'atomOneDarkReasonable',
    'atomOneDark',
    'atomOneLight',
    'brownPaper',
    'codepenEmbed',
    'colorBrewer',
    'darcula',
    'dark',
    'defaultStyle',
    'docco',
    'dracula',
    'far',
    'foundation',
    'githubGist',
    'github',
    'gml',
    'googlecode',
    'gradientDark',
    'gradientLight',
    'grayscale',
    'gruvboxDark',
    'gruvboxLight',
    'hopscotch',
    'hybrid',
    'idea',
    'irBlack',
    'isblEditorDark',
    'isblEditorLight',
    'kimbieDark',
    'kimbieLight',
    'lightfair',
    'lioshi',
    'magula',
    'monoBlue',
    'monokaiSublime',
    'monokai',
    'nightOwl',
    'nnfxDark',
    'nnfx',
    'nord',
    'obsidian',
    'ocean',
    'paraisoDark',
    'paraisoLight',
    'pojoaque',
    'purebasic',
    'qtcreatorDark',
    'qtcreatorLight',
    'railscasts',
    'rainbow',
    'routeros',
    'schoolBook',
    'shadesOfPurple',
    'solarizedDark',
    'solarizedLight',
    'srcery',
    'stackoverflowDark',
    'stackoverflowLight',
    'sunburst',
    'tomorrowNightBlue',
    'tomorrowNightBright',
    'tomorrowNightEighties',
    'tomorrowNight',
    'tomorrow',
    'vs',
    'vs2015',
    'xcode',
    'xt256',
    'zenburn',
]

const prefix = 'pages.settings.chat';
export const LABELS = makeLabels(prefix, {
    SELECT_THEME_LABEL: 'selectThemeLabel',
    BACKGROUND_LABEL: 'backgroundLabel',
    ENABLE: 'enable',
    DISABLE: 'disable',
    TITLE: 'title',
});