import { FileRecord } from "../../../types/app";
import { FileResponse } from "./types";

export const decorateFile = (data: FileResponse): FileRecord => ({
    clientId: data.client_id,
    collectionId: data.collection_id,
    name: data.filename,
    path: data.filepath,
    id: data.id,
    mimeType: data.mime_type,
    organizationId: data.organization_id,
    resourceId: data.resource_id,
    status: {
        description: data.status.status_description,
        text: data.status.status_text,
    },
    timestamp: data.timestamp,
    tokenCount: data.token_count,
    uuid: data.uuid,
});