import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../api"
import { rpcMethods } from "../../api/endpoints";
import { CapsuleItem, WorkspaceQuota } from "../../types/app";
import { UserInfo } from "../../types/user";

export interface FetchWorkspaceInfoResponse {
    users: UserInfo[];
    capsules: CapsuleItem[];
    name: string;
    quotas: {
        tokens: WorkspaceQuota;
        users: WorkspaceQuota;
        storage: WorkspaceQuota;
    }
}

interface FetchWorkspaceInfoArgs {
    id: string;
}

export const action = 'workspace/info'

export const fetchWorkspaceInfo = createAsyncThunk(
    action,
    async (args: FetchWorkspaceInfoArgs, thunkApi) => {
        const response = await callApi<FetchWorkspaceInfoResponse>(rpcMethods.workspaceInfo, args);
        return response;
    },
)