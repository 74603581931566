import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconGrid = ({
    width = 12,
    height = 12,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
        <Path d="M2.325 4.95006H3.375C4.425 4.95006 4.95 4.42506 4.95 3.37506V2.32506C4.95 1.27506 4.425 0.750061 3.375 0.750061H2.325C1.275 0.750061 0.75 1.27506 0.75 2.32506V3.37506C0.75 4.42506 1.275 4.95006 2.325 4.95006Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M8.625 4.95006H9.675C10.725 4.95006 11.25 4.42506 11.25 3.37506V2.32506C11.25 1.27506 10.725 0.750061 9.675 0.750061H8.625C7.575 0.750061 7.05 1.27506 7.05 2.32506V3.37506C7.05 4.42506 7.575 4.95006 8.625 4.95006Z" stroke="white" strokeMiterlimit="10" stroke-linecap="round" strokeLinejoin="round"/>
        <Path d="M8.625 11.25H9.675C10.725 11.25 11.25 10.725 11.25 9.67505V8.62505C11.25 7.57505 10.725 7.05005 9.675 7.05005H8.625C7.575 7.05005 7.05 7.57505 7.05 8.62505V9.67505C7.05 10.725 7.575 11.25 8.625 11.25Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M2.325 11.25H3.375C4.425 11.25 4.95 10.725 4.95 9.67505V8.62505C4.95 7.57505 4.425 7.05005 3.375 7.05005H2.325C1.275 7.05005 0.75 7.57505 0.75 8.62505V9.67505C0.75 10.725 1.275 11.25 2.325 11.25Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)