import { Dayjs } from "dayjs";

export enum WorkspaceEntityType {
    Workspace = 'workspace',
    Chat = 'chat',
    Folder = 'folder',
    Note = 'note',
}

export interface WorkspaceEntity {
    id: string;
    type: WorkspaceEntityType;
    name: string;
    isStarred: boolean;
}

export enum CapsuleStatus {
    Ready = 'READY',
}

export interface CapsuleItem {
    id: string;
    size: number;
    name: string;
    itemCount: number;
    status: CapsuleStatus;
    lastUpdate?: number;
    customInstructions: string;
    clientId: string;
    organizationId: string;
}

export interface WorkspaceQuota {
    used: number;
    total: number;
}

export interface Collection {
    id: string;
    name: string;
    customInstructions: string;
    clientId: string;
    organizationId: string;
}

export interface ModelPrompt {
    id: string;
    name: string;
    content: string;
}

export interface FileRecord {
    clientId: string;
    collectionId: string;
    name: string;
    path: string;
    id: string;
    mimeType: string;
    organizationId: string;
    resourceId: string;
    status: {
        description: string;
        text: string;
    };
    timestamp: number;
    tokenCount: 0;
    uuid: string;
}

export interface DialogRecord {
    uuid: string;
    title: string;
    lastMessageId: string | null;
}

export interface SpaceRecord {
    uuid: string;
    name: string;
    clientId: string;
    organizationId: string;
    instructions?: string;
    descrtiption?: string;
    isDefault: boolean;
    isFavourite: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    sessions?: DialogRecord[];
    collections?: CapsuleItem[];
}

export enum IntegrationType {
    Teams = 'teams',
    Slack = 'slack',
    Telegram = 'telegram',
}

export enum IntegrationStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Error = 'Error',
}

export interface Integration {
    id: string;
    name: string;
    description: string;
    icon: string | null;
    clientId: string;
    organizationId: string;
    type: IntegrationType;
    status: IntegrationStatus;
    createdAt: number;
    updatedAt: number | null;
    config: Record<string, string>;
}