import styled from "@emotion/styled";
import { TableCellContent } from "../../../../components/table/styles";
import { Path, Svg } from "../../../../components/icons/styles";

export const IconImage = styled.img((props) => ({
    width: 26,
    height: 26,
    backgroundColor: !props.src ? props.theme.colors.icons.stroke.primary : 'none',
    border: 0,
    borderRadius: 26,
}))

export const Name = styled.span(() => ({

}));

export const Description = styled.span(() => ({

}));

export const CreatedDate = styled.span(() => ({
    opacity: 0.5,
}));

export const Actions = styled.div(() => ({
    display: 'flex',
    gap: 15,
}));

export const TableCellContentSmall = styled(TableCellContent)(() => ({
    minHeight: 29,
}));

export const CellWithImage = styled(TableCellContentSmall)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    // @ts-ignore
    [Path]: {
        stroke: '#19FF67',
    },
    // @ts-ignore
    [Svg]: {
        minWidth: 12,
        minHeight: 12,
    }
}));