import { Container, Indicator } from "./styles"

interface LoadingIndicatorProps {
    compact?: boolean;
    size?: number;
    fullWidth?: boolean;
}

export const LoadingIndicator = ({ compact, size, fullWidth }: LoadingIndicatorProps) => {

    return (
        <Container fullWidth={fullWidth} compact={compact}>
            <Indicator size={size} />
        </Container>
    );
}