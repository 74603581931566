import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";

export interface ToggleIntegrationResponse {
    uuid: string;
    is_success: boolean;
}

interface ToggleIntegrationArgs {
    id: string;
}

export const action = 'integration/toggle'

export const toggleIntegration = createAsyncThunk(
    action,
    async (args: ToggleIntegrationArgs, thunkApi) => {
        const response = await callApi<ToggleIntegrationResponse>(rpcMethods.integrations.test, {
            uuid: args.id,
        });

        return response.is_success;
    },
)