import { Button } from 'components/buttons';
import { Profile, ProfileButtons } from '../styles';
import { LABELS, supportedLanguages } from '../keys';
import { Select } from 'components/inputs/select';
import { useTranslation } from 'react-i18next';

interface ProfilePanelProps {
    language: string;
    onChangeLanguage: (_: any, language: string) => void;
    goToSettings: () => void;
    logout: () => void;
}

export const ProfilePanel = ({
    language,
    onChangeLanguage,
    goToSettings,
    logout,
}: ProfilePanelProps) => {
    const { t } = useTranslation();

    return (
        <Profile>
            <Select
                options={supportedLanguages}
                value={language}
                defaultValue={language}
                onChange={onChangeLanguage}
            />
            <ProfileButtons>
                <Button flat onClick={goToSettings}>
                    {t(LABELS.SETTINGS)}
                </Button>
                <Button flat onClick={logout}>
                    {t(LABELS.LOGOUT)}
                    {/* <ProfileImage src={profileImageMock} /> */}
                </Button>
            </ProfileButtons>
        </Profile>
    )
}