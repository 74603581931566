import { makeLabels } from "../../../utils/labels";

const prefix = 'components.inputs.fileDropzone';
export const LABELS = makeLabels(prefix, {
    DEFAULT_PLACEHOLDER: 'defaultPlaceholder',
    SUPPORTED_FORMATS: 'supportedFormats',
});

export const defaultAllowedFormats = {
    'image/jpeg': ['.jpg'],
    'image/png': ['.png'],
    'image/pdf': ['.pdf'],
    'text/plain': ['.txt', '.csv'],
  }