import { useLocation, useNavigate } from "react-router";
import { Settings } from "./settings";
import { navigationItems } from "./keys";
import { SettingsMenuInteractiveItem } from "./types";
import { useMemo } from "react";

export const SettingsContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const interactiveItems: SettingsMenuInteractiveItem[] = useMemo(() => navigationItems.map(
        item => ({
            ...item,
            onNavigate: () => navigate(item.route),
            active: location.pathname === item.route,
        })
    ), [navigate, location.pathname]);

    return <Settings availableSettings={interactiveItems} />;
}