import { useTranslation } from "react-i18next";
import { Button } from "components/buttons";
import { Modal } from "components/modal";
import { ModalContainer, ModalHeader, ModalPanel } from "components/modal/styles";
import { defaultConfigs, LABELS } from "./keys";
import { ConfigFields, ConfigRow, Fields, NameLabel, TypeButton, TypeSelector } from "./styles";
import { Input } from "components/inputs/input";
import { ChangeEvent, useState, KeyboardEvent, useEffect } from "react";

import { KeyboardKeys } from "constants/keyboard";
import { Integration, IntegrationType } from "types/app";
import { isEmpty } from "lodash";
import { IconTrash } from "components/icons/trash";

interface IntegrationModalProps {
    open: boolean;
    isLoading: boolean;
    selectedIntegration: Integration | null;
    onClose: () => void;
    onAddIntegration: (integration: Pick<Integration, 'type' | 'config'>) => void;
}

const buildConfig = (input: [string, string][]): Record<string, string> => {
    return input.reduce((acc, [key, value]) => {
        if (key) {
            return {...acc, [key]: value };
        }

        return acc;
    }, {})
}

export const IntegrationModal = ({ open, onClose, onAddIntegration, isLoading: isCreating, selectedIntegration }: IntegrationModalProps) => {
    const [name, setName] = useState<string>(selectedIntegration?.name ?? '');
    const [type, setType] = useState<IntegrationType>(selectedIntegration?.type ?? IntegrationType.Slack);
    const [config, setConfig] = useState<[string, string][]>(Object.entries(
        selectedIntegration?.config ? selectedIntegration.config : defaultConfigs[type]
    ));
    // const [token, setToken] = useState<string>(selectedIntegration?.token ?? '');
    const [description, setDescription] = useState<string>(selectedIntegration?.description ?? '');
    const { t } = useTranslation();

    useEffect(() => {
        setName(selectedIntegration?.name ?? '');
        // setToken(selectedIntegration?.token ?? '');
        setDescription(selectedIntegration?.description ?? '');
    }, [selectedIntegration]);

    const handleClose = () => {
        onClose();
        setName('');
        setDescription('');
    }

    const addConfigRow = () => {
        setConfig(oldConfig => [...oldConfig, ['', '']]);
    }

    const removeConfigRow = (index: number) => () => {
        setConfig(oldConfig => {
            return oldConfig.filter((record, recordIndex) => recordIndex !== index);
        });
    }

    const updateConfigValue = (recordIndex: number, propIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
        setConfig(oldConfig => {
            const nextConfig = [...oldConfig];
            nextConfig[recordIndex][propIndex] = e.target.value;
            return nextConfig;
        })
    }

    const handleSubmit = async () => {
        await onAddIntegration({ type, config: buildConfig(config) });
        onClose();
    };

    const handleNameKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardKeys.Enter) {
            handleSubmit();
        }
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleTokenChange = (e: ChangeEvent<HTMLInputElement>) => {
        // setToken(e.target.value);
    };
    
    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleSelectType = (value: IntegrationType) => () => {
        setType(value);
        if (!selectedIntegration) {
            setConfig(Object.entries(defaultConfigs[value]));
        }
    }

    const isSubmitDisabled = (isEmpty(buildConfig(config)) || isCreating);

    const availaleTypes = Object.values(IntegrationType);

    if (selectedIntegration) {
        return (
            <Modal open={open} onClose={handleClose}>
                <ModalContainer>
                    <Button flat onClick={handleClose}>
                        {t(LABELS.CLOSE_BUTTON_TITLE)}
                    </Button>
                    <ModalPanel>
                        <ModalHeader>
                            {t(selectedIntegration ? LABELS.VIEW_TITLE : LABELS.TITLE)}
                        </ModalHeader>
                        <Fields>
                            <NameLabel>{t(LABELS.NAME_LABEL)}</NameLabel>
                            <Input
                                fullWidth
                                placeholder={t(LABELS.NAME_PLACEHOLDER)}
                                value={name}
                                disabled
                                onChange={handleNameChange}
                                onKeyDown={handleNameKeyDown}
                            />
                            <Input
                                fullWidth
                                // @ts-ignore
                                multiline
                                disabled
                                placeholder={t(LABELS.DESCRIPTION_PLACEHOLDER)}
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                            <NameLabel>{t(LABELS.CONFIG_LABEL)}</NameLabel>
                            <ConfigFields>
                                {config.map((record, index) => (
                                    <ConfigRow>
                                        <Input
                                            fullWidth
                                            placeholder={t(LABELS.KEY_PLACEHOLDER)}
                                            value={record[0]}
                                            disabled
                                            onChange={updateConfigValue(index, 0)}
                                        />
                                        <Input
                                            fullWidth
                                            placeholder={t(LABELS.VALUE_PLACEHOLDER)}
                                            value={record[1]}
                                            disabled
                                            onChange={updateConfigValue(index, 1)}
                                        />
                                    </ConfigRow>
                                ))}
                            </ConfigFields>
                        </Fields>
                    </ModalPanel>
                </ModalContainer>
            </Modal>
        );
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalContainer>
                <Button flat onClick={handleClose}>
                    {t(LABELS.CLOSE_BUTTON_TITLE)}
                </Button>
                <ModalPanel>
                    <ModalHeader>
                        {t(selectedIntegration ? LABELS.VIEW_TITLE : LABELS.TITLE)}
                    </ModalHeader>
                    <TypeSelector>
                        {availaleTypes.map(typeItem => (
                            <TypeButton key={typeItem} selected={typeItem === type} onClick={handleSelectType(typeItem)}>
                                {typeItem}
                            </TypeButton>
                        ))}
                    </TypeSelector>
                    <Fields>
                        <NameLabel>{t(LABELS.CONFIG_LABEL)}</NameLabel>
                        <ConfigFields>
                            {config.map((record, index) => (
                                <ConfigRow>
                                    <Input
                                        fullWidth
                                        placeholder={t(LABELS.KEY_PLACEHOLDER)}
                                        value={record[0]}
                                        disabled={isCreating}
                                        onChange={updateConfigValue(index, 0)}
                                    />
                                    <Input
                                        fullWidth
                                        placeholder={t(LABELS.VALUE_PLACEHOLDER)}
                                        value={record[1]}
                                        disabled={isCreating}
                                        onChange={updateConfigValue(index, 1)}
                                    />
                                    <Button icon onClick={removeConfigRow(index)}>
                                        <IconTrash />
                                    </Button>
                                </ConfigRow>
                            ))}
                            <Button onClick={addConfigRow}>
                                {t(LABELS.ADD_CONFIG_ROW)}
                            </Button>
                        </ConfigFields>
                    </Fields>
                    <Button secondary disabled={isSubmitDisabled} onClick={handleSubmit}>
                        {t(selectedIntegration ? LABELS.UPDATE_LABEL : LABELS.SUBMIT_LABEL)}
                    </Button>
                </ModalPanel>
            </ModalContainer>
        </Modal>
    );
}