import { Container, Controls, CreateCapsuleButton, CreateCapsuleButtonContent, CreateCapsuleButtonIcon } from './styles';
import { IconCapsule } from '../../../components/icons/capsule';
import { useTranslation } from 'react-i18next';
import { LABELS } from './keys';
import { CapsuleItem, CapsuleStatus } from '../../../types/app';
import { AddCapsuleModal, CapsuleControls } from './addCapsuleModal';
import { useState } from 'react';
import { ProfilePanel } from './components/profile';
import { CapsuleSection } from './components/capsuleSection';

interface SidebarProps {
    capsules: CapsuleItem[];
    enabledCapsules: string[];
    language: string;
    onChangeLanguage: (language: string) => void;
    logout: () => void;
    goToSettings: () => void;
    isFetching: boolean;
    capsuleControls: CapsuleControls;
};

export const Sidebar = ({
    capsules,
    logout,
    goToSettings,
    capsuleControls,
    enabledCapsules,
    language,
    onChangeLanguage,
    isFetching,
}: SidebarProps) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCapsule, setSelectedCapsule] = useState<CapsuleItem | undefined>(undefined);
    const { t } = useTranslation();

    const removeCapsule = (capsule: CapsuleItem) => {
        capsuleControls.onDeleteCapsule(capsule.id);
    }

    const openCapsuleModal = (capsule?: CapsuleItem) => {
        if (capsule) {
            setSelectedCapsule(capsule);
        }

        setModalOpen(true);
    }

    const adjustedCapsuleControls: CapsuleControls = {
        ...capsuleControls,
        onCreateCapsule: async (name: string) => {
            const capsule = await capsuleControls.onCreateCapsule(name);

            setSelectedCapsule(capsule);
            return capsule;
        },
    };

    const closeCapsuleModal = () => {
        setModalOpen(false);
        setSelectedCapsule(undefined);
    };

    const handleChangeCapsuleState = (capsule: CapsuleItem) => () => {
        if (capsule.status === CapsuleStatus.Ready) {
            capsuleControls.toggleCapsuleState(capsule.id);
        }
    };

    const handleChangeLanguage = (_: any, language: string) => {
        onChangeLanguage(language);
    };

    return (
        <>
        <AddCapsuleModal capsule={selectedCapsule} open={isModalOpen} onClose={closeCapsuleModal} capsuleControls={adjustedCapsuleControls} />
        <Container>
            <Controls>
                <ProfilePanel
                    language={language}
                    onChangeLanguage={handleChangeLanguage}
                    logout={logout}
                    goToSettings={goToSettings}
                />
                <CreateCapsuleButton onClick={() => openCapsuleModal()}>
                    <CreateCapsuleButtonContent>
                        <CreateCapsuleButtonIcon>
                            <IconCapsule width={13} height={13} />
                        </CreateCapsuleButtonIcon>
                        {t(LABELS.CREATE_CAPSULE_LABEL)}
                    </CreateCapsuleButtonContent>
                </CreateCapsuleButton>
            </Controls>
            <CapsuleSection
                capsules={capsules}
                enabledCapsules={enabledCapsules}
                removeCapsule={removeCapsule}
                openCapsuleModal={openCapsuleModal}
                onCapsuleStateChange={handleChangeCapsuleState}
                isFetching={isFetching}
            />
        </Container>
        </>
    );
}