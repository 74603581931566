import { ChatList } from "./list";

import { useSelector } from "react-redux";
import { selectChats } from "../../../state/slices/entities";
import { PreselectedPromptsContainer } from "../../components/preselectedPrompts/container";
import { WorkspaceEntity } from "../../../types/app";
import { useNavigate } from "react-router";
import { routePath } from "../../../router/path";


export const ChatListContainer = () => {
    const chats = useSelector(selectChats);
    const navigate = useNavigate();

    const handleOpenChat = (item: WorkspaceEntity) => {
        navigate(routePath.chatById(item.id));
    };

    return (
        <>
            <PreselectedPromptsContainer />
            <ChatList chats={chats} openChat={handleOpenChat} />
        </>
        
    );
}

export default ChatListContainer;