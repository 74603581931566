import { useTranslation } from "react-i18next";
import { entityIconMap } from "./item";
import { GridItemContainer, Head, Info, InfoText, Name } from "./styles.grid";
import { Button } from "../../buttons";
import { IconStar } from "../../icons/star";
import { IconDots } from "../../icons/dots";
import { LABELS } from "../keys";
import { EntityItemProps } from "./types";

export const EntityGridItem = ({ id, name, type, isStarred, onClick }: EntityItemProps) => {
    const { t } = useTranslation();
    const entityIcon = entityIconMap[type];

    const handleItemClick = () => {
        onClick?.({
            id,
            name,
            type,
            isStarred,
        });
    };

    return (
        <GridItemContainer onClick={handleItemClick}>
            <Head>
                <Button icon>
                    {entityIcon}
                </Button>
                <div>
                    <Button icon>
                        <IconStar filled={isStarred}  width={16} height={16} />
                    </Button>
                    <Button icon>
                        <IconDots width={16} height={16} />
                    </Button>
                </div>
            </Head>
            <Info>
                <Name>
                    {name}
                </Name>
                {/* <InfoText>
                    {t(LABELS.LAST_UPDATED, { count: Number((Math.random() * 30).toFixed(0)), units: 'days' })}
                </InfoText> */}
            </Info>
            
        </GridItemContainer>
    );
}