import styled from "@emotion/styled";
import { ButtonRoot } from "../../../components/buttons/button/styles";

export const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: 15,
    gap: 20,
    boxSizing: 'border-box' as const,
    height: '100%',
    width: '100%',
}));

export const Content = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 20,
}))

export const ChatMessages = styled.div(() => ({
    flex: 2,
    maxWidth: 560,
    width: '100%',
    maxHeight: 'calc(100vh - 50px)',
    overflowX: 'hidden' as const,
    overflowY: 'hidden' as const,
    boxSizing: 'border-box' as const,
    // position: 'absolute' as const,
}));

export const Scrollable = styled.div(() => ({
    width: '100%',
    paddingRight: 22,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    maxHeight: '100vh',
    overflowY: 'auto' as const,
    gap: 20,
    '::-webkit-scrollbar': {
        display: 'none',
    },
}));

export const Bumper = styled.div(() => ({
    minHeight: 100,
    width: '100%',
}))

export const DropdownPanel = styled.div((props) => ({
    maxHeight: '300px',
    backdropFilter: 'blur(10px)',
    borderRadius: 5,
    border: `1px solid ${props.theme.colors.background.primary}`,
    overflow: 'hidden',
}));

export const DropdownScrollable = styled(Scrollable)(() => ({
    gap: 5,
    maxHeight: '300px',
    // @ts-ignore
    [ButtonRoot]: {
        padding: '10px 0',
        border: 0,
    }
}));

export const SettingsContainer = styled.div(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 20,
}));

export const SettingsItem = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
}));

export const SettingsItemLabel = styled.div((props) => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    fontSize: 12,
    alignItems: 'center',
    color: props.theme.colors.text.primary,
}));

export const SettingsItemControl = styled.div(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    // @ts-ignore
    [ButtonRoot]: {
        padding: '10px 8px',
    }
}));

export const Title = styled.h2((props) => ({
    color: props.theme.colors.text.primary,
}))