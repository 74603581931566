
import { ReactElement } from "react";
import { Overlay, StyledModal } from "./styles";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    children: ReactElement;
}

export const Modal = ({ open, children, onClose }: ModalProps) => {
    return (
        <StyledModal open={open} onClose={onClose} slots={{ backdrop: Overlay }}>
            {children}
        </StyledModal>
    );
};