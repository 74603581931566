import { ReactNode } from "react";
import { SelectRoot, SelectOption, SelectOptions } from "./styles"
import { SelectItem } from "./types"

interface SelectProps<T extends SelectItem> {
    value?: string;
    defaultValue?: string;
    options: T[];
    placeholder?: string;
    onChange?: (_: any, values: string) => void,
    renderOption?: (option: T) => ReactNode;
}

const defaultRenderOption = (option: SelectItem) => (
    <SelectOption value={option.key} key={option.key}>
        {option.label}
    </SelectOption>
);

export const Select = <T extends SelectItem>({
    options,
    value,
    defaultValue,
    placeholder,
    onChange,
    renderOption = defaultRenderOption,
}: SelectProps<T>) => {
    return (
        <SelectRoot
            // @ts-ignore
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            slots={{
                listbox: SelectOptions
            }}
        >
            {options.map(renderOption)}
        </SelectRoot>
    );
};