import { AsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../state/store";

interface StatedAutoRequestOptions {
    skip?: boolean;
}

export const useStatedAutoRequest = <T, A>(
    thunk: AsyncThunk<T, A, any>,
    args: A,
    options?: StatedAutoRequestOptions,
) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(undefined);
    const [data, setData] = useState<T | undefined>(undefined);
    const memoedArgs = useMemo(() => args, [JSON.stringify(args)]);

    const dispatch = useAppDispatch();

    const invoke = useCallback((): Promise<PayloadAction<T, any, never, never>> | undefined => {
        setError(undefined);
        setLoading(true);
        try {
            const promise = dispatch(thunk(memoedArgs));
            promise.then((response) => {
                const output = response.payload as T;
                if (!output) {
                    return;
                }
                setData(output);
                setLoading(false);
            });

            return promise as Promise<PayloadAction<T, any, never, never>>;
        } catch (e: unknown) {
            setError(e);
            setLoading(false);
        }
    }, [memoedArgs]);

    useEffect(() => {
        const shouldPerformCall = !options?.skip;
        let controller: Promise<PayloadAction<T, any, never, never>> | undefined ;
        if (shouldPerformCall) {
            setLoading(false);
            controller = invoke();
        }
        
        return () => {
            // @ts-ignore
            controller?.abort?.();
            setLoading(false);
        };
    }, [memoedArgs, options?.skip]);

    return {
        isLoading,
        error,
        data,
    };
} 

export const useStatedRequest = <T, A>(
    thunk: AsyncThunk<T, A, any>,
) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(undefined);
    const [data, setData] = useState<T | undefined>(undefined);

    const dispatch = useAppDispatch();

    const invoke = useCallback((passedArgs: A): Promise<PayloadAction<T, any, never, never>> | undefined => {
        setError(undefined);
        setLoading(true);
        try {
            const promise = dispatch(thunk(passedArgs));
            promise.then((response) => {
                const output = response.payload as T;
                setData(output);
                setLoading(false);
            });

            return promise as Promise<PayloadAction<T, any, never, never>>;
        } catch (e: unknown) {
            setError(e);
            setLoading(false);
        }
    }, []);

    return {
        isLoading,
        error,
        invoke,
        data,
    }
} 