import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Storage } from 'utils/storage';

import ru from './ru.json';
import en from './en.json';
import fr from './fr.json';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        ru,
        en,
        fr,
    },
    lng: Storage.get('ug-language') || 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;