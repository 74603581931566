import styled from "@emotion/styled";

export const Fields = styled.div(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    gap: 15,
    input: {
        width: '100%',
    }
}));

export const NameLabel = styled.label((props) => ({
    color: props.theme.colors.text.primary,
    opacity: 0.5,
    fontSize: 12,
    lineHeight: 1.1,
}))

export const Files = styled.div<{ visible?: boolean }>((props) => ({
    display: props.visible ? 'flex' : 'none',
    flexDirection: 'column',
    maxHeight: 200,
    position: 'relative',
    overflowY: 'auto' as const,
    '::-webkit-scrollbar': {
        display: 'none',
    },
}));
export const FilesList = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    maxHeight: 200,
    position: 'relative' as const,
    overflowY: 'auto' as const,
}))

export const SelectedFile = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column',
    color: props.theme.colors.text.primary,
    fontSize: 14,
    padding: '20px 0',
    gap: 10,
    lineHeight: 1.1,
    '&:not(:first-of-type)': {
        borderTop: '1px solid rgba(217, 217, 217, 0.20)',
    },
    button: {
        opacity: 0.5,
    },
}));

export const FileName = styled.span((props) => ({
    color: props.theme.colors.text.primary,
    opacity: 0.5,
}))

export const FileInfo = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

export const ProgressText = styled.span(() => ({
    minWidth: 30,
}));

export const Progress = styled.span((props) => ({
    color: props.theme.colors.text.primary,
    fontSize: 12,
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    span: {
        opacity: 0.5,
    },
}))

export const ProgressBar = styled.div<{ progress: number, color?: string }>((props) => ({
    height: 4,
    width: 150,
    borderRadius: 4,
    backgroundColor: props.theme.colors.background.primary,
    ':after': {
        content: '""',
        display: 'flex',
        width: (1.5 * props.progress),
        height: 4,
        transition: 'width .2s linear',
        borderRadius: 4,
        backgroundColor: props.color ?? props.theme.colors.cyan,
    }
}));

export const ShadeBumper = styled.div(() => ({
    background: '#040509',
    boxShadow: '0px 0px 25px 25px #000',
    width: '100%',
    minHeight: 10,
    position: 'absolute' as const,
    bottom: 0,
}));