import { SingleLineInputProps as MuiInputProps, InputOwnProps , Input as MuiInput } from "@mui/base";
import { InputRoot, Input as InputSlot, Textarea as TextareaSlot } from "./styles";

type InputProps = MuiInputProps & InputOwnProps & {
    fullWidth?: boolean;
}

export const Input = (props: InputProps) => {
    return (
        <MuiInput
            slots={{
                root: InputRoot,
                input: InputSlot,
                textarea: TextareaSlot,
            }}
            slotProps={{
                root: {
                    className: props.className,
                },
                // @ts-ignore
                input: {
                    autoFocus: props.autoFocus,
                    ref: props.inputRef,
                },
                textarea: {
                    autoFocus: props.autoFocus,
                    ref: props.inputRef,
                },
            }}
            {...props}
        />
    )
}