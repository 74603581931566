import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import { ChatSettings } from "./settings";
import { ChatMessage } from "./types";
import { selectVisualSettings, setCodeBackground, setCodeTheme } from "../../../state/slices/chat";

const messages: ChatMessage[] = [
    {
        uuid: 'EkI7jm4qkoPhljHg',
        user: {
            imageUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASjSURBVHgBjVbfayNVFP7mzjQ7TdNsSNMwu6QhdkspC9ZYEFZ8ycNSfKgiPoj7IOz65rOggn+B++7TPtjHvokPogiyAUGDiBbBUkotQylLjGkM2WzNj5kze86dmWy2bbo95GaSueee73znx73XwAvk6xWUYFklUFAiI8iQj4zH7wNCLwiMOpFR93xv+56L+kV2jEkT35VRIpgVNsRPgCgc4DHQGjR6J6+NwHB9368yoHspoK9KsBeyVsUnukXamNLGBlB41CP8wyizFlBMhAAYA5Onb1DN9CGAvYlAD8vIDM2p9w0jcEIjodc/tYCtBuFgbGmOwT50FF5JsZqnQt3wgSGoPkXYYrD2GaCHzAT5qbuEwNFe8qLf24QHR4T9E0yUz4oKL9vQCzwd25BdBLYZM1PxAuuaVbGU7ygV5uBLl/Dp3sUgIvcPCe5A2BCULA4/mIJyYKIS65ny9cstTrxSG5xRqMDAFweEb/+lcw2LsdnZWUxPTyOZTHIxBtjuEl6fBa4g4BApBPzOYDSuysI7VwP3mzbamhFNTVW0N6z0Wxf4oUkTGYjeYDCA4zhYXFxEPp9HBwk8aCieo0gnjJ88TNOsaEa/vgamqG4bki7DwM8tH7X/gokg8/PzKJVKyGQyGmh5eRlc1vi79Rhr9hBpM2AmioMT6ALgkXmbWVm4YpVHiWI3ut5EMty3FhKJBA4PD9FqtZBKpVAoFLC/vw/btvHjiY0PMj1mIo0RlaBeh5KCZThh1PQUCvZkEBEJ1dLSErLZLDzPw9raGjY2NjTQwBtEzRVVmQopBIFZsjh1TqA0jp64kQyZnRZJ/OrqKorFIjtKOk87OzvY29vD+vo62u02co922UZzxCROmCLKWBxD24he6O5WoddirNlsjvS73S6Ojo40oIAIG8mZhE30ZL6Yz6E3aMAWIhaFzsvOolTGwlh+ROrct7KQ6HlW8l+Aer2eDp2ASL6kKAREGMn/k6RCGBQKLUpKGMz8qGSWueZtLjjuIe5oHs0bb2gw8Vp6YhxMgET6/b6em5ubw8LCAobDIY6Pj3E75WFGhfXG9acJGIHqSQBH+5GEbdkewDr8U4dPPDwtAt5oNKLwh6wEPJfL8Z7n8R6ooKLoKIS9GQRenX8b7ihvUa4+djooWy1MEmEW58h1XR02AcvbXP5qzJYucu4py2Igz3PHjYiCwyX++fUO1pKDc4HEaKfT0SW+srKCdDqt+yqnd0mKmFBkjWD1vW31alUOqsA9Y429vVPARImr8ODgYJTPtBW5qvAMjA/E9/j0DauO/CqUdfe0sdU0Vynre3R++OKQSbmLONl4ckxxyLYRhVJYcaHUniPEI8UoAnaRCJCAvpkB3sqOnTsspqFqd6KjffQ+7XtV3lhHFwyKZm++ACiWFW6eZLSVySC+uDzpe9Vxx7W8VOVbDQ23YrB4YjFp4TLi9sJyl5V8rNVPZoZb4/eGcaYSwvZVGm6yQ7U4zNcSl8Lhi0v4NODVHvdp8972s/uCyBl3hRmn//s/KvYuKb9yPRWUcAn5639yOVnVd3cved06Lfdvzjif7Dwp808nGvFB0o6GOzYmylMOLzYEabmoZAAAAABJRU5ErkJggg==',
            name: 'anthropic/claude-3-5-sonnet-20240620',
            handle: ''
        },
        message: '```python\nimport random\nfrom typing import List, Dict\n\nclass ColorfulCode:\n    def __init__(self, name: str):\n        self.name = name\n        self.colors = ["red", "green", "blue", "yellow"]\n\n    def generate_rainbow(self) -> List[str]:\n        return [random.choice(self.colors) for _ in range(5)]\n\n    @staticmethod\n    def print_info(data: Dict[str, any]) -> None:\n        for key, value in data.items():\n            print(f"{key}: {value}")\n\n# Creating class insance\ncode_example = ColorfulCode("Syntax Highlighter")\n\n# Generating random color sequence\nrainbow = code_example.generate_rainbow()\n\n# Creating info dictionary\ninfo = {\n    "name": code_example.name,\n    "colors": code_example.colors,\n    "rainbow": rainbow,\n    "timestamp": "2024-11-03 19:03:54"\n}\n\n# Printing info\nColorfulCode.print_info(info)\n```',
        info: {
            tokens: 0
        }
    }
];

export const ChatSettingsContainer = () => {

    const dispatch = useAppDispatch();
    const visualSettings = useSelector(selectVisualSettings);
    const handleChangeTheme = (theme: string) => {
        dispatch(setCodeTheme(theme));
    }

    const handleChangeBackground = () => {
        dispatch(setCodeBackground(!visualSettings.codeBackground));
    }

    return (
        <ChatSettings messages={messages} visual={visualSettings} onChangeTheme={handleChangeTheme} onChangeBackground={handleChangeBackground} />
    );
}

export default ChatSettingsContainer;