import styled from "@emotion/styled";
import { Modal as MUIModal } from "@mui/base";

export const StyledModal = styled(MUIModal)((props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    inset: 0,
    position: 'fixed' as const,
}));

export const Overlay = styled.div(() => ({
    backdropFilter: 'blur(10px)',
    zIndex: -1,
    position: 'fixed' as const,
    inset: 0,
}));

export const ModalContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
}));

export const ModalPanel = styled.div(() => ({
    padding: 40,
    borderRadius: 24,
    background: '#030508',
    border: '1px solid rgba(217, 217, 217, 0.10)',
    boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.50)',
    maxWidth: 320,
    display: 'flex',
    flexDirection: 'column' as const,
    gap: 30,
}));

export const ModalHeader = styled.div((props) => ({
    color: props.theme.colors.white,
    textAlign: 'center',
    fontSize: 28,
    lineHeight: 1.15,
}));