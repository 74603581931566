import { WorkspaceEntity } from "../../types/app";
import { EntityList } from "../../components/entityList/list";
import { Container, StyledMessageInput } from "./styles"
import { LoadingIndicator } from "../../components/loading/indicator";
import { useNavigate } from "react-router";
import { routePath } from "../../router/path";
import { useTranslation } from "react-i18next";
import { LABELS } from "./keys";
import { useState } from "react";

interface HomeProps {
    entities: WorkspaceEntity[];
    onChatStart: (message: string) => Promise<string | undefined>;
    navigateToChat: (chatId: string) => void;
    isLoading: boolean;
}

export const Home = ({ entities, isLoading, onChatStart, navigateToChat }: HomeProps) => {
    const { t } = useTranslation();
    const [inputState, setInputState] = useState<boolean>(false);
    const navigate = useNavigate();
    const handleItemClick = (item: WorkspaceEntity) => {
        navigate(routePath.chatById(item.id));
    }
    
    const handleStartChat = async (message: string): Promise<boolean> => {
        const chatId = await onChatStart(message);
        if (!chatId) {
            return false;
        }

        setInputState(true);
        setTimeout(() => {
            navigateToChat(chatId)
        }, 300);

        return true;
    }

    if (isLoading) {
        return (
            <Container engaged={false}>
                <LoadingIndicator />
            </Container>
        )
    }

    return (
        <Container engaged={inputState}>
            <StyledMessageInput engaged={inputState} placeholder={t(LABELS.INPUT_PLACEHOLDER)} sendMessage={handleStartChat} />
            <EntityList items={entities} onItemClick={handleItemClick} />
        </Container>
    );
}