import { useTranslation } from "react-i18next";
import { Button } from "../../buttons";
import { IconArrow } from "../../icons/arrow";
import { IconClip } from "../../icons/clip";
import { IconGear } from "../../icons/gear";

import { Container, InputWrapper, InputContainer, MessageHint, SendButton } from "./styles";
import { LABELS } from "./keys";
import { Input } from "../input";
import { ChangeEvent, useState, KeyboardEvent, useRef, useEffect } from "react";
import { KeyboardKeys } from "../../../constants/keyboard";
import { LoadingIndicator } from "../../loading/indicator";

interface MessageInputProps {
    sendMessage: (message: string) => Promise<boolean>;
    placeholder?: string;
    withSettings?: boolean;
    withAttachments?: boolean;
    className?: string;
    disabled?: boolean;
    isWaiting?: boolean;
}

export const MessageInput = ({ placeholder, sendMessage, withAttachments, withSettings, className, disabled, isWaiting }: MessageInputProps) => {
    const [message, setMessage] = useState<string>('');
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>();

    const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        if (!isWaiting) {
            inputRef.current?.focus();
        }
    }, [isWaiting]);

    const handleMessageSend = async () => {
        const result = await sendMessage(message);
        if (result) {
            setMessage('');
            inputRef.current?.focus();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardKeys.Enter) {
            handleMessageSend();
        }
    };

    return (
        <Container className={className}>
            <InputContainer>
                {
                    withAttachments &&
                    (<Button icon>
                        <IconClip />
                    </Button>)
                }
                <InputWrapper>
                    <Input
                        value={message}
                        onChange={handleMessageChange}
                        fullWidth
                        autoFocus
                        // @ts-ignore
                        inputRef={inputRef}
                        disabled={disabled || isWaiting}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder ?? t(LABELS.PLACEHOLDER)}
                        endAdornment={(
                            isWaiting
                            ? <LoadingIndicator compact size={24} />
                            : <SendButton disabled={disabled} onClick={handleMessageSend}><IconArrow /></SendButton>
                        )}
                    />
                </InputWrapper>
                {
                    withSettings &&
                    <Button icon>
                        <IconGear />
                    </Button>
                }
            </InputContainer>
            <MessageHint>{t(LABELS.MESSAGE_HINT)}</MessageHint>
        </Container>
    );
}