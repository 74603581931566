import styled from "@emotion/styled/macro";
import { GridContainer } from "../../components/entityList/styles";
import { MessageInput } from "../../components/inputs/messageInput/messageInput";

export const Container = styled.div<{ engaged: boolean }>(({ engaged }) => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
}));

export const Presets = styled.div(() => ({

}));

export const StyledMessageInput = styled(MessageInput)<{ engaged: boolean }>(({ theme, engaged }) => ({
    position: 'relative' as const,
    top: engaged ? 'calc(100vh - 205px)' : 0,
    transition: 'all .3s ease',
    input: {
        fontSize: 20,
        "::placeholder": {
            color: theme.colors.text.primary,
        },
    },
    '& + div': engaged ? {
        display: 'none',
    } : {},
}));