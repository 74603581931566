import { useTranslation } from "react-i18next";
import SyntaxHighlighter from 'react-syntax-highlighter'
import * as hljs from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IconNote } from "../../icons/note";
import { Body, Container, ControlButton, Controls, Footer, Header, Message, TokenInfo, UserCard, UserHandle, UserImage, UserInfo, UserName } from "./styles";
import { IconLink } from "../../icons/link";
import { IconTrash } from "../../icons/trash";
import { IconCopy } from "../../icons/copy";
import { IconPencil } from "../../icons/pencil";
import { useSelector } from "react-redux";
import { selectVisualSettings } from "../../../state/slices/chat";

interface ModelMessageProps {
    user: {
        imageUrl?: string;
        name: string;
        handle: string;
    };
    message: string;
    info: {
        tokens: number;
    };
}

export const SyntaxHighlight = (props: any) => {
    // exception
    const visualSettings = useSelector(selectVisualSettings);
    let highlightStlye = hljs.vs2015;
    const { codeTheme, codeBackground } = visualSettings;
    if (codeTheme in hljs) {
        highlightStlye = (hljs as Record<string, any>)[codeTheme];
    }

    const {children, className, node, ...rest} = props
    const match = /language-(\w+)/.exec(className || '')
    return match ? (
        <SyntaxHighlighter
        {...rest}
        styles={{
            backgroundColor: 'transparent',
            background: 'transparent',
        }}
        PreTag="div"
        children={String(children).replace(/\n$/, '')}
        language={match[1]}
        style={{
            ...highlightStlye,
            hljs: {
                ...highlightStlye.hljs,
                ...(codeBackground ? {
                    background: highlightStlye.hljs.background,
                    backgroundColor: highlightStlye.hljs.backgroundColor || highlightStlye.hljs.background,
                } : {
                    backgroundColor: 'transparent',
                    background: 'transparent',
                }),
            },
        }}
        />
    ) : (
        <code {...rest} className={className}>
            {children}
        </code>
    )
}

export const ModelMessage = ({
    user,
    message,
    info,
}: ModelMessageProps) => {
    const { t } = useTranslation();

    const handleCopyMessage = () => {
        navigator?.clipboard.writeText(message);
    };

    return (
        <Container>
            <Header>
                <UserCard>
                    <UserImage src={user.imageUrl} />
                    <UserInfo>
                        <UserName>{user.name}</UserName>
                        <UserHandle>{user.handle}</UserHandle>
                    </UserInfo>
                </UserCard>
                <Controls>
                    {/* <ControlButton icon>
                        <IconNote width={11} height={11} />
                    </ControlButton>
                    <ControlButton icon>
                        <IconLink />
                    </ControlButton>
                    <ControlButton icon>
                        <IconTrash />
                    </ControlButton> */}
                </Controls>
            </Header>
            <Body>
                <Message
                    components={{
                        code: SyntaxHighlight
                    }}
                >
                    {message}
                </Message>
            </Body>
            <Footer>
                <TokenInfo>
                    {/* {t(LABELS.TOKENS_USED, info)} */}
                </TokenInfo>
                <Controls>
                    <ControlButton icon onClick={handleCopyMessage}>
                        <IconCopy />
                    </ControlButton>
                    {/* <ControlButton icon>
                        <IconPencil />
                    </ControlButton> */}
                </Controls>
            </Footer>
        </Container>
    );
}