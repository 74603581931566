import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";

export interface CreateChatResponse {
    chat_id: string;
}

interface CreateChatArgs {
}

export const action = 'chat/create'

const decorateResponse = (response: CreateChatResponse) => ({
    chatId: response.chat_id,
});

export const createChat = createAsyncThunk(
    action,
    async (args: CreateChatArgs, thunkApi) => {
        const response = await callApi<CreateChatResponse>(rpcMethods.chat.create, args);
        return decorateResponse(response);
    },
)