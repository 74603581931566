import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router"
import { DesktopLayout, SettingsDesktopLayout } from "../layout/desktop"
import { routePath } from "./path"
import ChatContainer from "../pages/chat/chat/container"
import { useSelector } from "react-redux"
import LoginContainer from "../pages/login/container"
import { HomeContainer } from "../pages/home/container"
import ChatListContainer from "../pages/chat/list/container"
import { useEffect } from "react"
import { ChatSettingsContainer } from "../pages/settings/chat/container"
import IntegrationSettingsContainer from "pages/settings/integrations/container"
import { selectCurrentUser } from "state/slices/user"

export const MainRouter = () => {
    const user = useSelector(selectCurrentUser);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const shouldNavigate = location.pathname === routePath.root
            && location?.state?.navigateAfterRedirect
            && user;

        if (shouldNavigate) {
            navigate(location?.state?.navigateAfterRedirect);
        }
    }, [user, location]);

    if (!user) {
        return (
            <Routes>
                <Route path={routePath.root} element={<LoginContainer />} />
                <Route path="*" element={<Navigate to={routePath.root} state={{ navigateAfterRedirect: location.pathname }} />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route element={<DesktopLayout />}>
                <Route path={routePath.root} element={<HomeContainer />} />
                <Route path={routePath.chat.root} element={<ChatListContainer />} />
                <Route path={routePath.chat.byId} element={<ChatContainer />} />
                {/* <Route path={routePath.workspaces.root} element={<WorkspaceListContainer />} />
                <Route path={routePath.workspaces.byId} element={<WorkspaceViewContainer />} /> */}
                {/* <Route path={routePath.folders.byId} element={<FolderViewContainer />} /> */}
                {/* <Route path="*" element={<Navigate to={routePath.chat.root} />} /> */}
            </Route>
            <Route element={<SettingsDesktopLayout />}>
                <Route path={routePath.settings.chat} element={<ChatSettingsContainer />} />
                <Route path={routePath.settings.integrations} element={<IntegrationSettingsContainer />} />
                <Route path={routePath.settings.root} element={null} />
            </Route>
        </Routes>
    )
}