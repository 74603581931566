import { useTranslation } from "react-i18next";
import { Section, SectionTitle } from "../styles"
import { List, MenuItem } from "./styles"
import { SettingsMenuInteractiveItem } from "./types";
import { LABELS } from "./keys";

interface SettingsProps {
    availableSettings: SettingsMenuInteractiveItem[];
}

export const Settings = ({ availableSettings }: SettingsProps) => {
    const { t } = useTranslation();

    return (
        <Section>
            <SectionTitle>{t(LABELS.TITLE)}</SectionTitle>
            <List>
                {availableSettings.map(record => (
                    <MenuItem key={record.route} active={record.active} onClick={record.onNavigate}>{t(record.label)}</MenuItem>
                ))}
            </List>
        </Section>
    )
}