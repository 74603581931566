import dayjs from "dayjs";
import { SpaceRecord } from "../../../types/app";
import { ServerSpaceItem } from "./types";
import { decorateDialogRecord } from "../chat/utils";
import { decorateCapsule } from "../collections/utils";

const DATE_FORMAT = 'DD-MM-YYYY';

export const decorateSpace = (space: ServerSpaceItem): SpaceRecord => ({
    uuid: space.uuid,
    name: space.name,
    clientId: space.client_id,
    organizationId: space.organization_id,
    isDefault: space.is_default,
    isFavourite: space.is_favourite,
    createdAt: space.created_at ? dayjs(space.created_at).format(DATE_FORMAT) : '',
    updatedAt: space.updated_at ? dayjs(space.updated_at).format(DATE_FORMAT) : '',
    deletedAt: space.deleted_at ? dayjs(space.deleted_at).format(DATE_FORMAT) : '',
    sessions: space?.sessions?.map(decorateDialogRecord),
    collections: space?.collections?.map(decorateCapsule),
});