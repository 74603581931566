import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/buttons";
import { LABELS } from "./keys";
import { FileInfo, FileName, Files, FilesList, Progress, ProgressBar, ProgressText, SelectedFile } from "./styles";
import { FileRecord } from "types/app";
import { LoadingIndicator } from "components/loading/indicator";

interface FilesSectionProps {
    acceptedFiles: File[];
    items: FileRecord[];
    fileProgress: Record<string, number>;
    onCancelUpload: (item: File) => () => void;
    onRemove: (item: FileRecord) => () => void;
    isFetching: boolean;
}

export const FilesSection = ({
    acceptedFiles,
    items,
    fileProgress,
    onCancelUpload,
    onRemove,
    isFetching,
}: FilesSectionProps) => {
    const { t } = useTranslation();
    
    const preparedFiles = items.map(file => ({
        file,
        progress: 100,
    }));

    const areFilesVisible = acceptedFiles.length + preparedFiles.length > 0;

    if (isFetching) {
        return (
            <Files visible>
                <LoadingIndicator size={30} />
            </Files>
        );
    }

    console.log(fileProgress);

    return (
        <Files visible={areFilesVisible}>
            <FilesList>
            {acceptedFiles.map((item: File) => (
                <SelectedFile key={item.name}>
                    <FileName>{item.name}</FileName>
                    <FileInfo>
                        <Progress>
                            <ProgressText>{(fileProgress[item.name])?.toFixed?.(0) + '%'}</ProgressText>
                            <ProgressBar progress={fileProgress[item.name]} />
                        </Progress>
                        {
                            fileProgress[item.name] !== 100 &&
                            (<Button flat onClick={onCancelUpload(item)}>{t(LABELS.CANCEL_BUTTON_LABEL)}</Button>)
                        }
                    </FileInfo>
                </SelectedFile>
            ))}
            {preparedFiles.map((item) => (
                <SelectedFile key={item.file.id}>
                    <FileName>{item.file.name}</FileName>
                    <FileInfo>
                        <Progress>
                        </Progress>
                        <Button flat onClick={onRemove(item.file)}>{t(LABELS.REMOVE_BUTTON_LABEL)}</Button>
                    </FileInfo>
                </SelectedFile>
            ))}
            </FilesList>
        </Files>
    );
}