import { makeLabels } from "../../utils/labels";

const prefix = 'pages.login';
export const LABELS = makeLabels(prefix, {
    TITLE: 'title',
    LOGIN_BUTTON_LABEL: 'loginButtonLabel',
    LOGIN_PLACEHOLDER: 'loginPlaceholder',
    PASSWORD_PLACEHOLDER: 'passwordPlaceholder',
    RESET_BUTTON_LABEL: 'resetPasswordLabel',
    RESET_TITLE: 'resetTitle',
    EMAIL_PLACEHOLDER: 'emailPlaceholder',
})