import styled from '@emotion/styled/macro';

export const Svg = styled.svg(() => ({
    fill: 'none',
}));

export const Path = styled.path((props) => ({
    stroke: props.theme.colors.icons.stroke.primary,
}));

export const Polyline = styled.polyline();