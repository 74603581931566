import { useTranslation } from "react-i18next"
import { Section, SectionTitle } from "../styles"
import { LABELS } from "./keys";
import { WorkspaceButton, WorkspaceButtonText, WorkspaceIcon, Workspaces } from "./styles";
import { AppWorkspace } from "../../../../../types/chat";

interface WorkspaceSectionProps {
    workspaces: AppWorkspace[];
    navigateToWorkspace: (id: string) => void;
}

export const WorkspaceSection = ({ workspaces, navigateToWorkspace }: WorkspaceSectionProps) => {
    const { t } = useTranslation();

    const getNavigateHandler = (id: string) => () => {
        // navigateToWorkspace(id);
    }

    return (
        <Section>
            <SectionTitle>
                {t(LABELS.TITLE)}
            </SectionTitle>
            <Workspaces>
                {workspaces.map(workspace => (
                    <WorkspaceButton key={workspace.id} onClick={getNavigateHandler(workspace.id)}>
                        <WorkspaceIcon src={workspace.iconUrl} />
                        <WorkspaceButtonText>
                            {workspace.name}
                        </WorkspaceButtonText>
                    </WorkspaceButton>
                ))}
            </Workspaces>
        </Section>
    );
};