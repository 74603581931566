import styled from "@emotion/styled";
import { InputRoot } from "../../components/inputs/input/styles";
import { Button } from "../../components/buttons";

export const Container = styled.div(() => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative' as const,
}));

export const Panel = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20,
    background: props.theme.colors.white,
    padding: '30px 20px 20px 20px',
    borderRadius: 26,
}));

export const Header = styled.div((props) => ({
    fontSize: 16,
    lineHeight: 1.1,
    fontWeight: 400,
    color: props.theme.colors.text.secondary,
}));

export const Body = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20,
}));

export const Fields = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    // @ts-ignore
    [InputRoot]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 10,
        padding: '8px 14px',
        minWidth: 240,
        boxSizing: 'border-box',
        color: props.theme.colors.text.dark,
        border: `1px solid ${props.theme.colors.input.border}`,
        input: {
            width: '100%',
            color: props.theme.colors.text.secondary,
            opacity: 1,
        },
    },
}));

export const Buttons = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 8,
    minWidth: 240,
}));

export const LoginButton = styled(Button)((props) => ({
    width: '100%',
    border: 0,
    borderRadius: 12,
    fontSize: 14,
    lineHeight: 1.1,
    height: 40,
}));

export const LogoutButton = styled(Button)((props) => ({
    color: props.theme.colors.text.secondary,
}));

export const Logo = styled.img(() => ({
    width: 90,
    height: 21,
    position: 'absolute' as const,
    top: 20,
}));