import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = styled.div<{ fullWidth?: boolean; compact?: boolean }>(({ compact, fullWidth }) => ({
    padding: compact ? 0 : 30,
    width: fullWidth ? '100%' : 'auto',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
}));

const rotationAnimation = keyframes`
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(180deg);
    }
`;

export const Indicator = styled.div<{ size?: number }>((props) => ({
    border: `2px dashed ${props.theme.colors.accent}`,
    animation: `${rotationAnimation} 2s infinite`,
    width: props.size || 32,
    height: props.size || 32,
    borderRadius: 32,
    backgroundColor: 'transparent',
}));