import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconDots = ({
    width = 3,
    height = 12,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 3 12" fill="none">
        <Path d="M2 1C2 0.725 1.775 0.5 1.5 0.5C1.225 0.5 1 0.725 1 1C1 1.275 1.225 1.5 1.5 1.5C1.775 1.5 2 1.275 2 1Z" />
        <Path d="M2 11C2 10.725 1.775 10.5 1.5 10.5C1.225 10.5 1 10.725 1 11C1 11.275 1.225 11.5 1.5 11.5C1.775 11.5 2 11.275 2 11Z" />
        <Path d="M2 6C2 5.725 1.775 5.5 1.5 5.5C1.225 5.5 1 5.725 1 6C1 6.275 1.225 6.5 1.5 6.5C1.775 6.5 2 6.275 2 6Z" />
    </Svg>
)