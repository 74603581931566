import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconTrash = ({
    width = 11,
    height = 11,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 11" fill="none">
        <Path d="M10.5 2.71191C8.65254 2.52547 6.78814 2.43225 4.9322 2.43225C3.83051 2.43225 2.72881 2.49157 1.63559 2.60174L0.5 2.71191" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M3.55908 2.15254L3.67773 1.42373C3.76247 0.898302 3.83026 0.5 4.77094 0.5H6.22857C7.16925 0.5 7.23704 0.915252 7.32179 1.42373L7.44043 2.14407" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M9.30518 2.7796L8.94077 8.37282C8.88144 9.2457 8.8306 9.92367 7.27975 9.92367H3.71195C2.1611 9.92367 2.11025 9.2457 2.05092 8.37282L1.68652 2.7796" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)