import { Path, Svg } from "./styles"
import { IconProps } from "./types";

const path = [
    [
        'M4.725 11.5169H4.4C1.8 11.5169 0.5 10.8689 0.5 7.62861V4.38834C0.5 1.79611 1.8 0.5 4.4',
        '0.5H9.6C12.2 0.5 13.5 1.79611 13.5 4.38834V7.62861C13.5 10.2208 12.2 11.5169 9.6',
        '11.5169H9.275C9.0735 11.5169 8.8785 11.6142 8.755 11.7762L7.78 13.0723C7.351 13.6426',
        '6.649 13.6426 6.22 13.0723L5.245 11.7762C5.141 11.6336 4.9005 11.5169 4.725 11.5169Z',
    ].join(' '),
    'M3.75 4.38831H10.25',
    'M3.75 7.6286H7.65',
];

export const IconConversation = ({
    width = 15,
    height = 14,
}: IconProps) => (
    <Svg width={width} height={height} viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
        <Path d={path[0]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[1]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[2]} strokeLinejoin="round" strokeLinecap="round" />
    </Svg>
)