import { useTranslation } from "react-i18next";
import { CentralSection, HeaderContainer, ModelItem, ModelItemIcon, ModelItemPreview, ModelList, ModelPanel, ModelSelectContainer, ModelSelectLabel, ModelTypeButton, ModelTypeButtons, SearchAdornment, SearchBar, SearchBarContainer } from "./styles";
import { DropdownButton } from "../../../components/buttons/dropdownButton/dropdownButton";
import { ChangeEvent, useState } from "react";
import { IconSearch } from "../../../components/icons/search";
import { GenerativeModel } from "../../../types/models";
import { IconChevron } from "../../../components/icons/chevron";

interface ModelSelectProps {
    models: GenerativeModel[];
    selectedModel: GenerativeModel | null;
    onModelChange: (model: GenerativeModel) => void;
}

export const ModelSelect = ({ models, selectedModel, onModelChange }: ModelSelectProps) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>('');

    const selectModel = (model: GenerativeModel) => () => onModelChange(model);
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
    }

    const visibleModels = search
        ? models.filter(model => model.name.includes(search) || model.key.includes(search))
        : models;

    return (
        <HeaderContainer>
            <CentralSection>
                <ModelSelectContainer>
                    <DropdownButton
                        buttonProps={(open) => ({
                            children: (<ModelItemPreview open={open}>
                                <ModelItemIcon src={selectedModel?.icon} />
                                {selectedModel?.name}
                                <IconChevron />
                            </ModelItemPreview>),
                        })}
                        popperProps={{
                            placement: 'bottom',
                        }}
                    >
                        <ModelPanel>
                            <SearchBarContainer>
                                <SearchBar fullWidth onChange={handleSearch} endAdornment={<SearchAdornment><IconSearch /></SearchAdornment>} />
                            </SearchBarContainer>
                            <ModelList>
                                {visibleModels.map(model => (
                                    <ModelItem key={model.key} flat onClick={selectModel(model)} >
                                        <ModelItemIcon src={model.icon} />
                                        {model.name}
                                    </ModelItem>
                                ))}
                            </ModelList>
                        </ModelPanel>
                    </DropdownButton>
                </ModelSelectContainer>
            </CentralSection>
        </HeaderContainer>
    );
};