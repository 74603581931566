import { makeLabels } from "utils/labels";

const prefix = 'blocks.sidebar';
export const LABELS = makeLabels(prefix, {
    CREATE_CAPSULE_LABEL: 'createCapsuleLabel',
    LIST_TITLE: 'listTitle',
    VIEW_BUTTON_LABEL: 'viewButtonLabel',
    REMOVE_CAPSULE: 'removeCapsule',
    EDIT_CAPSULE: 'editCapsule',
    LOGOUT: 'logout',
    SETTINGS: 'settings',
});

export const supportedLanguages = [{
    key: 'ru',
    value: 'ru',
    label: 'Русский',
}, {
    key: 'en',
    value: 'en',
    label: 'English',
}, {
    key: 'fr',
    value: 'fr',
    label: 'Francais',
}]