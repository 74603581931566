export const colors = {
    background: {
        secondary: '#292929',
        primary: '#030508',
    },
    border: {
        primary: '#4e4d4d',
    },
    text: {
        primary: '#D9D9D9',
        dark: '#E3E3E3',
        inactive: '#D9D9D9',
        secondary: '#030508',
        placeholder: '#646464',
        highlight: '#ffffff',
    },
    icons: {
        stroke: {
            primary: '#D9D9D9',
        },
    },
    input: {
        border: '#D9D9D9',
    },
    accent: '#4ca3f1',
    line: '#4B4B4B',
    selection: '#323232',
    heading: '#BCCDDE',
    white: '#ffffff',
    cyan: '#1EFFE4',
    secondary: '#F65E44',
    third: '#1EFFE4',
    brand: {
        active: '#B784F9',
        default: '#534664',
    },
    event: {
        warning: '#FFD02B',
        success: '#19FF67',
        error: '#F20000',
    },
    
}

export const darkTheme = {
    colors,
}