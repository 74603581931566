import { makeLabels } from "../../../../utils/labels";

const prefix = 'blocks.sidebar.addCapsuleModal';
export const LABELS = makeLabels(prefix, {
    CLOSE_BUTTON_TITLE: 'closeButtonTitle',
    TITLE: 'title',
    EDIT_TITLE: 'editTitle',
    NAME_LABEL: 'nameLabel',
    NAME_PLACEHOLDER: 'namePlaceholder',
    LIMIT_LABEL: 'limitLabel',
    SUBMIT_LABEL: 'submitLabel',
    CHANGE_NAME_LABEL: 'changeName',
    REMOVE_BUTTON_LABEL: 'removeButtonLabel',
    CANCEL_BUTTON_LABEL: 'cancelButtonLabel',
});