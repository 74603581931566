import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkspaceState } from "../types";
import { selectSelf } from "./utils";
import { fetchWorkspaceInfo, FetchWorkspaceInfoResponse } from "../thunks/fetchWorkspaceData";

const initialState: WorkspaceState = {
    users: [],
    capsules: [],
    name: '',
    quotas: {
        users: { used: 0, total: 0 },
        tokens: { used: 0, total: 0 },
        storage: { used: 0, total: 0 },
    }
}

export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        updateName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWorkspaceInfo.fulfilled, (state, action: PayloadAction<FetchWorkspaceInfoResponse>) => {
            state.users = action.payload.users;
            state.capsules = action.payload.capsules;
            state.name = action.payload.name;
            state.quotas = action.payload.quotas;
        })
    }
});

export const { updateName } = workspaceSlice.actions;

export const selectWorkspaceCapsules = createSelector(selectSelf, state => state.workspace.capsules);
export const selectWorkspaceUsers = createSelector(selectSelf, state => state.workspace.users);
export const selectWorkspaceQuotas = createSelector(selectSelf, state => state.workspace.quotas);
export const selectWorkspaceName = createSelector(selectSelf, state => state.workspace.name);