export const apiEndpoints = {
    rpc: '/api',
    auth: {
        login: '/auth/login',
        logout: '/auth/logout',
        register: '/auth/register',
        userInfo: '/auth/info'
    },
    upload: '/api/upload',
};

export const rpcMethods = {
    entities: 'entities/list',
    prompts: 'prompts/list',
    capsules: 'capsule/list',
    workspaceInfo: 'workspace/info',
    integrations: {
        add: 'integrations/add',
        remove: 'integrations/remove',
        list: 'integrations/list',
        info: 'integrations/info',
        test: 'integrations/test',
        toggle: 'integrations/toggle',
    },
    spaces: {
        get: 'spaces/get',
        create: 'spaces/create',
        list: 'spaces/list',
        delete: 'spaces/delete',
        update: 'spaces/update',
        chatList: 'spaces/{id}/chats',
    },
    chat: {
        create: 'chat/init_chat',
        update: 'chat/update',
        sendMessage: 'chat/send_message',
        history: 'chat/history',
        modelList: 'chat/get_model_list',
        list: 'chat/my_chats',
    },
    rag: {
        query: 'rag/query',
        search: 'rag/search',
    },
    url: {
        add: 'url/add',
        list: 'url/list',
        update: 'url/update',
        delete: 'url/delete',
    },
    files: {
        list: 'files/list',
        status: 'files/status',
        delete: 'files/delete',
        reindex: 'files/reindex',
    },
    collections: {
        add: 'collections/add',
        list: 'collections/list',
        rename: 'collections/rename',
        delete: 'collections/delete',
        updateCustomInstructions: 'collections/update_custom_instructions',
    },
}