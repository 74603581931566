import { Path, Svg } from "./styles"
import { IconProps } from "./types";

export const IconCopy = ({
    width = 11,
    height = 11,
}: IconProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 11" fill="none">
        <Path d="M7.5 5.95V8.05C7.5 9.8 6.8 10.5 5.05 10.5H2.95C1.2 10.5 0.5 9.8 0.5 8.05V5.95C0.5 4.2 1.2 3.5 2.95 3.5H5.05C6.8 3.5 7.5 4.2 7.5 5.95Z" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M10.5 2.95V5.05C10.5 6.8 9.8 7.5 8.05 7.5H7.5V5.95C7.5 4.2 6.8 3.5 5.05 3.5H3.5V2.95C3.5 1.2 4.2 0.5 5.95 0.5H8.05C9.8 0.5 10.5 1.2 10.5 2.95Z" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
)