import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { RawIntegrationResponse } from "./types";
import { IntegrationType } from "types/app";
import { decorateIntegration } from "./utils";

export interface CreateIntegrationResponse {
    integration: RawIntegrationResponse;
}

interface CreateIntegrationArgs {
    type: IntegrationType;
    config: Record<string, string>;
}

export const action = 'integration/add'

export const addIntegration = createAsyncThunk(
    action,
    async (args: CreateIntegrationArgs, thunkApi) => {
        const response = await callApi<CreateIntegrationResponse>(rpcMethods.integrations.add, args);
        return decorateIntegration(response.integration);
    },
)