import { useNavigate } from "react-router";
import { ActionsSection } from "./actions"
import { routePath } from "router/path";
import { useStatedRequest } from "api/hooks/useStatedRequest";
import { createChat } from "state/thunks/chat/createChat";
import { useState } from "react";
import { AddSpaceModal } from "./addSpaceModal";
import { createSpace } from "state/thunks/spaces/addSpace";

export const ActionsSectionContainer = () => {
    const [isSpaceModalOpen, setSpaceModalOpen] = useState<boolean>(false);
    const { invoke: invokeCreateChat, isLoading: isChatCreating } = useStatedRequest(createChat);
    const { invoke: invokeCreateSpace, isLoading: isSpaceCreating } = useStatedRequest(createSpace);
    const navigate = useNavigate();

    const openSpaceModal = () => {
        setSpaceModalOpen(true);
    };

    const closeSpaceModal = () => {
        setSpaceModalOpen(false);
    };

    const goToWorkspaces = () => {
        navigate(routePath.workspaces.root);
    };

    const onChatCreate = async () => {
        const result = await invokeCreateChat({});
        if (!result) {
            return;
        }

        const { chatId } = result.payload;
        navigate(routePath.chatById(chatId));
    }

    const onSpaceCreate = async (args: { name: string; description?: string; }) => {
        const result = await invokeCreateSpace(args);
        if (!result) {
            return;
        }

        // const { uuid } = result.payload;
        // navigate(routePath.spaceById(uuid));
    }

    return (
        <>
            <AddSpaceModal
                open={isSpaceModalOpen}
                onClose={closeSpaceModal}
                onCreateSpace={onSpaceCreate}
                isCreating={isSpaceCreating}
            />
            <ActionsSection
                createChat={onChatCreate}
                createNote={() => {}}
                createWorkspace={goToWorkspaces}
                createSpace={openSpaceModal}
            />
        </>
    );
}