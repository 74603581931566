import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CapsulesState } from "../types";
import { selectSelf } from "./utils";
import { fetchCollectionsList } from "../thunks/collections/fetchCollectionsList";
import { addCollection } from "../thunks/collections/addCollection";
import { deleteCollection } from "../thunks/collections/deleteCollection";
import { renameCollection } from "../thunks/collections/renameCollection";
import { showError } from "providers/notifications/notificationProvider";

const initialState: CapsulesState = {
    capsules: [],
    selectedCapsule: null,
}

export const capsulesSlice = createSlice({
    name: 'capsules',
    initialState,
    reducers: {
        selectCapsule: (state, action: PayloadAction<any | null>) => {
            state.selectedCapsule = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCollectionsList.fulfilled, (state, action) => {
            state.capsules = action.payload.items;
        })
        .addCase(addCollection.fulfilled, (state, action) => {
            state.capsules.push(action.payload);
        })
        .addCase(renameCollection.fulfilled, (state, action) => {
            state.capsules = state.capsules.map((capsule) => {
                if (capsule.id === action.payload.id) {
                    return {
                    ...capsule,
                    name: action.payload.name
                    }
                }

                return capsule;
            });
        })
        .addCase(renameCollection.rejected, (state, action) => {
            showError(action.error.message || '');
        })
        .addCase(deleteCollection.fulfilled, (state, action) => {
            state.capsules = state.capsules.filter(capsule => capsule.id !== action.payload);
        })
    }
});

export const selectCapsules = createSelector(selectSelf, state => state.capsules.capsules);