import { MouseEvent, ReactNode } from "react";
import { ButtonType } from "./types";
import { ButtonRoot } from "./styles";

export interface ButtonProps {
    primary?: boolean;
    flat?: boolean;
    icon?: boolean;
    forwardRef?: (ref?: HTMLElement | null) => void;
    secondary?: boolean;
    onClick?: (event: MouseEvent) => void;
    children?: ReactNode | ReactNode[] | string;
    className?: string;
    disabled?: boolean;
}

const getButtonType = ({primary, secondary, flat, icon }: ButtonProps): ButtonType => {
    if (secondary) {
        return ButtonType.Secondary;
    }

    if (flat) {
        return ButtonType.Flat;
    }

    if (icon) {
        return ButtonType.Icon;
    }

    return ButtonType.Primary;
}

export const Button = (props: ButtonProps) => {
    const type: ButtonType = getButtonType(props);

    const { children, onClick, className, forwardRef, disabled } = props;

    return (
        <ButtonRoot ref={forwardRef} buttonType={type} onClick={onClick} className={className} disabled={disabled}>
            {children}
        </ButtonRoot>
    );
}