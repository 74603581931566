import { createSelector, createSlice } from "@reduxjs/toolkit";
import { PromptsState } from "../types";
import { selectSelf } from "./utils";
import { prompts } from "../../api/responseMocks/prompts";

const initialState: PromptsState = {
    prompts: prompts,
}

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState,
    reducers: {
        
    }
});

export const selectPrompts = createSelector(selectSelf, state => state.prompts.prompts);