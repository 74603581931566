import styled from '@emotion/styled';
import { Button } from '../../../components/buttons';
import { Path } from '../../../components/icons/styles';
import { ButtonRoot } from 'components/buttons/button/styles';

export const Container = styled.div((props) => ({
    gridColumn: 'sidebar-start / sidebar-end',
    gridRow: 'header-start / end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderLeft: `1px solid rgba(217, 217, 217, 0.10)`,
    backdropFilter: 'blur(15px)',
}));

export const Controls = styled.div(() => ({
    padding: 20,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid rgba(217, 217, 217, 0.10)`,
    width: '100%',
    boxSizing: 'border-box' as const,
}));

export const Profile = styled.div(() => ({
    display: 'flex',
    flex: 1,
    width: '100%',
    paddingBottom: 54,
    justifyContent: 'space-between',
    flexDirection: 'row-reverse' as const,
    alignItems: 'center',

    [`${ButtonRoot}`]: {
        padding: 3,
    },
}));

export const ProfileImage = styled.img(() => ({
    
}));

export const CreateCapsuleButtonContent = styled.div(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
    fontSize: 12,
    lineHeight: 1.1,
}));

export const CreateCapsuleButtonIcon = styled.div(() => ({
    height: 13,
}));

export const CreateCapsuleButton = styled(Button)(() => ({
    padding: '0 12px',
}));

export const CapsuleListHeader = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: props.theme.colors.text.primary,
    width: '100%',
    padding: '18px 15px',
    boxSizing: 'border-box',
    fontSize: 12,
    lineHeight: 1.1,
}));

export const CapsuleList = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '0 15px',
    boxSizing: 'border-box' as const,
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto' as const,
}));

export const CapsuleListItem = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box' as const,
    color: props.theme.colors.text.primary,
    fontSize: 12,
    gap: 8,
    lineHeight: 1.15,
}));

export const CapsuleIcon = styled(Button)<{ active: boolean; ready: boolean }>(({ active, ready, theme }) => {
    const iconColor = ready
        ?  (active ? '#19FF67' : '#D9D9D9')
        : theme.colors.event.warning;

    return {
        height: 12,
        [`${Path}`]: {
            stroke: iconColor,
        },
    };
});

export const CapsuleItemText = styled.div(() => ({
    flex: 1,
}));

export const CapsuleSettingsButton = styled.div(() => ({}));

export const DropdownActions = styled.div((props) => ({
    border: `1px solid ${props.theme.colors.border.primary}`,
    borderRadius: 5,
    backdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    gap: 4,
}));

export const ActionItem = styled(Button)((props) => ({
    border: 0,
    flex: 1,
    padding: '6px 8px',
    textAlign: 'left',
    justifyContent: 'flex-start',
}))

export const ProfileButtons = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}));

export const CapsuleSectionContainer = styled.div(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
}));