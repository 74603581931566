import { Integration } from "types/app";
import { RawIntegrationResponse } from "./types";

export const decorateIntegration = (input: RawIntegrationResponse): Integration => ({
    id: input.uuid,
    name: input.name,
    description: input.description,
    icon: input.icon,
    createdAt: input.created_at,
    updatedAt: input.updated_at,
    config: input.config,
    type: input.type,
    status: input.status,
    clientId: input.client_id,
    organizationId: input.organization_id,
});