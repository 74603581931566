/* eslint-disable no-console */
export class Logger {
    static isEnabled: true;

    static log(...args: any[]) {
      if (Logger.isEnabled) {
        console.log(...args);
      }
    }
  
    static error(...args: any[]) {
      if (Logger.isEnabled) {
        console.error(...args);
      }
    }
  
    static warn(...args: any[]) {
      if (Logger.isEnabled) {
        console.warn(...args);
      }
    }
  
    static trace(...args: any[]) {
      if (Logger.isEnabled) {
        console.trace(...args);
      }
    }
  }
  
  export default Logger;