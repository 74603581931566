import { TableColumn } from "components/table/types";
import { makeLabels } from "utils/labels"

const prefix = 'pages.settings.integrations';
export const LABELS = makeLabels(prefix, {
    TITLE: 'title',
    REMOVE_INTEGRATION: 'removeIntegration',
    ADD_INTEGRATION: 'addIntegration',
    VIEW_INTEGRATION: 'viewIntegration',
    INTEGRATION_CHANNELS: 'integrationChannels',
    ENABLE_INTEGRATION: 'enableIntegration',
    DISABLE_INTEGRATION: 'disableIntegration',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
});

export const KEYS = {
    DATE_FORMAT: 'DD/MM/YYYY',
};

export const integrationTableColumnKeys: Record<string, string> = {
    name: 'name',
    description: 'description',
    createdAtt: 'createdAt',
    actions: 'actions',
};

export const integrationTableColumns: TableColumn[] = [{
    key: integrationTableColumnKeys.name,
    name: integrationTableColumnKeys.name,
}, {
    key: integrationTableColumnKeys.description,
    name: integrationTableColumnKeys.description,
}, {
    key: integrationTableColumnKeys.status,
    name: integrationTableColumnKeys.status,
}, {
    key: integrationTableColumnKeys.createdAt,
    name: integrationTableColumnKeys.createdAt,
}, {
    key: integrationTableColumnKeys.actions,
    name: integrationTableColumnKeys.actions,
    space: 2,
}];