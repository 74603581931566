import { capsuleList } from "./capsules";
import { userList } from "./users";


export const workspaceInfoResponse = {
    users: userList,
    capsules: capsuleList,
    name: 'Default workspace',
    quotas: {
        users: { used: userList.length, total: 8, left: 8 - userList.length },
        storage: { used: 120, total: 200, left: 80 },
        tokens: { used: 128746, total: 2000000, left: 2000000 - 128746 },
    },
};