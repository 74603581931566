import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './slices/auth'
import { capsulesSlice } from './slices/capsules'
import { entitiesSlice } from './slices/entities'
import { chatSlice } from './slices/chat'
import { userSlice } from './slices/user'
import { promptsSlice } from './slices/prompts'
import { useDispatch } from 'react-redux'
import { workspaceSlice } from './slices/workspace'
import { integrationsSlice } from './slices/integrations'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    capsules: capsulesSlice.reducer,
    chat: chatSlice.reducer,
    entities: entitiesSlice.reducer,
    user: userSlice.reducer,
    prompts: promptsSlice.reducer,
    workspace: workspaceSlice.reducer,
    integrations: integrationsSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): AppDispatch => {
  const dispatch = useDispatch();

  return dispatch as AppDispatch;
}