import { useDropzone } from "react-dropzone";
import { Container, Dropzone, Link, Placeholder, SupportedFormats } from "./styles"
import { defaultAllowedFormats, LABELS } from "./keys";
import { Trans } from "react-i18next";
import { useEffect } from "react";

interface FileDropzoneProps {
    placeholder?: string;
    acceptedFormats?: Record<string, string[]>;
    onChangeFiles?: (files: File[]) => void;
}
export const FileDropzone = ({
    placeholder,
    acceptedFormats = defaultAllowedFormats,
    onChangeFiles,
}: FileDropzoneProps) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: acceptedFormats,
    });

    useEffect(() => {
        onChangeFiles?.(acceptedFiles as File[]);
    }, [acceptedFiles]);

    const formats = Object.values(acceptedFormats).reduce((acc, current) => [...acc, ...current], []).join(', ');

    const usedPlaceholder = placeholder
        ?? <Trans i18nKey={LABELS.DEFAULT_PLACEHOLDER} components={{ a: <Link /> }} />

    return (
        <Container>
            <Dropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <Placeholder>
                    {usedPlaceholder}
                </Placeholder>
            </Dropzone>
            <SupportedFormats>
                <Trans i18nKey={LABELS.SUPPORTED_FORMATS} values={{ formats }} />
            </SupportedFormats>
        </Container>
    );
}