import { UserInfo } from "../../types/user";
import avatar from '../../assets/mocks/wsavatar.png';

export const userList: UserInfo[] = [{
    id: Math.random().toString(),
    name: 'Oleg Mongol',
    handle: '@olegmongol',
    imageUrl: avatar,
    email: 'olegmongol@gmail.com',
    lastActive: new Date().getTime(),
}, {
    id: Math.random().toString(),
    name: 'Pasha Technick',
    handle: '@pashatech',
    email: 'pashatechnick@outlook.com',
    lastActive: new Date().getTime(),
}, {
    id: Math.random().toString(),
    name: 'Ahmed Angel',
    handle: '@ahmedtheonly',
    imageUrl: avatar,
    lastActive: new Date().getTime(),
}, {
    id: Math.random().toString(),
    name: 'Vasya Pupkin',
    handle: '@thevasya',
    imageUrl: avatar,
    email: 'olegmongol@gmail.com',
    lastActive: new Date().getTime(),
}]

export const userListResponse = {
    response: userList,
};