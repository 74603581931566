import { ModelMessage } from "../../../components/chat/modelMessage";
import { Bumper, ChatMessages, Container, Content, DropdownPanel, DropdownScrollable, Scrollable, SettingsContainer, SettingsItem, SettingsItemControl, SettingsItemLabel, Title } from "./styles";
import { ChatMessage } from "./types";
import { LABELS, options } from "./keys";
import { DropdownButton } from "../../../components/buttons/dropdownButton/dropdownButton";
import { Button } from "../../../components/buttons";
import { useTranslation } from "react-i18next";

interface ChatProps {
    messages: ChatMessage[];
    visual: {
        codeBackground: boolean;
        codeTheme: string;
    };
    onChangeTheme: (theme: string) => void;
    onChangeBackground: () => void;
}

export const ChatSettings = ({ messages, visual, onChangeTheme, onChangeBackground }: ChatProps) => {
    const { t } = useTranslation();
    
    const handleSelect = (option: string) => () => {
        onChangeTheme(option)
    };

    return (
        <Container>
            <Title>
                {t(LABELS.TITLE)}
            </Title>
            <Content>
                <SettingsContainer>
                    <SettingsItem>
                        <SettingsItemLabel>
                            {t(LABELS.SELECT_THEME_LABEL)}
                        </SettingsItemLabel>
                        <SettingsItemControl>
                            <DropdownButton
                                buttonProps={(open) => ({
                                    primary: true,
                                    children: visual.codeTheme,
                                })}
                                popperProps={{
                                    placement: 'bottom-end',
                                }}
                            >
                                <DropdownPanel>
                                    <DropdownScrollable>
                                        {options.map(option => <Button onClick={handleSelect(option)}>{option}</Button>)}
                                    </DropdownScrollable>
                                </DropdownPanel>
                            </DropdownButton>
                        </SettingsItemControl>
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsItemLabel>
                            {t(LABELS.BACKGROUND_LABEL)}
                        </SettingsItemLabel>
                        <SettingsItemControl>
                            <Button onClick={onChangeBackground}>
                                {t(visual.codeBackground ? LABELS.DISABLE : LABELS.ENABLE)}
                            </Button>
                        </SettingsItemControl>
                    </SettingsItem>
                </SettingsContainer>
                <ChatMessages>
                    <Scrollable >
                        {messages.map(message => <ModelMessage key={message.uuid} {...message} />)}
                        <Bumper />
                    </Scrollable>
                </ChatMessages>
            </Content>
        </Container>
    );
}