import { CapsuleItem, CapsuleStatus } from "../../types/app";

export const capsuleList: CapsuleItem[] = [{
    id: '1',
    size: 120,
    name: 'first capsule',
    itemCount: 15,
    customInstructions: "",
    status: CapsuleStatus.Ready,
    clientId: "",
    organizationId: ""
}, {
    id: '2',
    size: 8000,
    name: 'very big capsule',
    itemCount: 30,
    customInstructions: "",
    status: CapsuleStatus.Ready,
    clientId: "",
    organizationId: ""
}, {
    id: '3',
    size: 10,
    name: 'Capsule with a very long long long long long long long long long long long long long long long long long name',
    itemCount: 15,
    customInstructions: "",
    clientId: "",
    status: CapsuleStatus.Ready,
    organizationId: ""
}, {
    id: '4',
    size: 300,
    name: 'Capsule with enormous item count',
    itemCount: 800000000,
    customInstructions: "",
    clientId: "",
    status: CapsuleStatus.Ready,
    organizationId: ""
}]

export const capsuleListResponse = {
    response: capsuleList,
};