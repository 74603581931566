import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { decorateCapsule } from "./utils";
import { ServerCapsuleItem } from "./types";

export interface AddCollectionResponse {
    data: ServerCapsuleItem;
}

interface AddCollectionArgs {
    name: string;
    customInstructions?: string;
}

export const action = 'collections/add'



export const addCollection = createAsyncThunk(
    action,
    async (args: AddCollectionArgs, thunkApi) => {
        const response = await callApi<AddCollectionResponse>(
            rpcMethods.collections.add,
            { collection_name: args.name, custom_instructions: args.customInstructions },
        );
        return decorateCapsule(response.data);
    },
)