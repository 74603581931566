import { ReactNode } from "react";
import { WorkspaceEntity, WorkspaceEntityType } from "../../../../../types/app";
import { Buttons, Item, ItemContent, ItemIcon, ItemInfo, ItemsList, Name, RemoveButton, StarButton } from "./styles";
import { IconConversation } from "../../../../../components/icons/conversation";
import { IconFolder } from "../../../../../components/icons/folder";
import { IconGrid } from "../../../../../components/icons/grid";
import { Section } from "../styles";
import { IconStar } from "../../../../../components/icons/star";
import { routePath } from "../../../../../router/path";
import { LoadingIndicator } from "components/loading/indicator";
import { IconTrash } from "components/icons/trash";

interface ItemsSectionProps {
    items: WorkspaceEntity[];
    isLoading: boolean;
}

const iconsMap: Record<WorkspaceEntityType, ReactNode> = {
    [WorkspaceEntityType.Chat]: <IconConversation />,
    [WorkspaceEntityType.Folder]: <IconFolder />,
    [WorkspaceEntityType.Workspace]: <IconGrid />,
    [WorkspaceEntityType.Note]: <IconConversation />,
};

export const ItemsSection = ({ items, isLoading }: ItemsSectionProps) => {
    const getItemLink = (item: WorkspaceEntity) => {
        if (item.type === WorkspaceEntityType.Chat) {
            return routePath.chatById(item.id);
        }

        if (item.type === WorkspaceEntityType.Folder) {
            return routePath.spaceById(item.id);
        }

        if (item.type === WorkspaceEntityType.Workspace) {
            return routePath.workspaceById(item.id);
        }

        return routePath.root;
    }

    if (isLoading) {
        return (
            <Section>
                <LoadingIndicator size={15} compact fullWidth />
            </Section>
        );
    }

    return (
        <Section>
            <ItemsList>
                {items.map(item => (
                    <Item key={item.id}>
                        <ItemContent>
                            <ItemInfo to={getItemLink(item)}>
                                <ItemIcon>
                                    {iconsMap[item.type]}
                                </ItemIcon>
                                <Name>
                                    {item.name}
                                </Name>
                            </ItemInfo>
                            <Buttons>
                                <StarButton>
                                    <IconStar width={10} height={10} filled={item.isStarred} />    
                                </StarButton>
                                {/* <RemoveButton>
                                    <IconTrash width={10} height={10} />    
                                </RemoveButton> */}
                            </Buttons>
                        </ItemContent>
                    </Item>
                ))}
            </ItemsList>
        </Section>
    );
}