import { WorkspaceEntityType } from "../../../types/app";
import { ReactElement } from "react";
import { IconConversation } from "../../icons/conversation";
import { IconFolder } from "../../icons/folder";
import { IconNote } from "../../icons/note";
import { IconWorkspace } from "../../icons/workspace";

export const entityIconMap: Record<WorkspaceEntityType, ReactElement> = {
    [WorkspaceEntityType.Chat]: <IconConversation width={16} height={16} />,
    [WorkspaceEntityType.Folder]: <IconFolder width={16} height={16} />,
    [WorkspaceEntityType.Note]: <IconNote width={16} height={16} />,
    [WorkspaceEntityType.Workspace]: <IconWorkspace width={16} height={16} />,
}
