import { useSelector } from "react-redux"
import { Home } from "./home"
import { selectEntities } from "../../state/slices/entities";
import { changeModel, selectModels, selectSelectedModel, setInitialMessage } from "../../state/slices/chat";
import { useAppDispatch } from "../../state/store";
import { GenerativeModel } from "../../types/models";
import { ModelSelect } from "../components/modelSelect/modelSelect";
import { getModelList } from "../../state/thunks/chat/getModelList";
import { useStatedAutoRequest, useStatedRequest } from "../../api/hooks/useStatedRequest";
import { useNavigate } from "react-router";
import { createChat } from "../../state/thunks/chat/createChat";
import { routePath } from "../../router/path";
import { showError } from "../../providers/notifications/notificationProvider";
import { getSpaceList } from "../../state/thunks/spaces/getSpaceList";

export const HomeContainer = () => {
    useStatedAutoRequest(getModelList, {});
    useStatedAutoRequest(getSpaceList, {});
    const entities = useSelector(selectEntities);
    const models = useSelector(selectModels);
    const selectedModel = useSelector(selectSelectedModel);
    const dispatch = useAppDispatch();
    const { invoke: invokeCreateChat } = useStatedRequest(createChat);
    const navigate = useNavigate();

    const handleModelChange = (model: GenerativeModel) => {
        dispatch(changeModel(model));
    };

    const handleChatStart = async (message: string) => {
        const result = await invokeCreateChat({});
        if (!result) {
            showError('Something went wrong!');
            return;
        }

        const { chatId } = result.payload;
        dispatch(setInitialMessage(message));
        return chatId;
    }

    const navigateToChat = (chatId: string) => {
        navigate(routePath.chatById(chatId));
    }

    return (
        <>
            <ModelSelect models={models} onModelChange={handleModelChange} selectedModel={selectedModel} />
            <Home entities={entities} isLoading={false} onChatStart={handleChatStart} navigateToChat={navigateToChat} />
        </>
        
    );
}