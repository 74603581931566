import { ChatMessage } from "../../types/chat";
import avatar from '../../assets/mocks/wsavatar.png';

export const chatMessages: ChatMessage[] = [{
    uuid: '1',
    user: {
        imageUrl: avatar,
        name: 'Karen Charykov',
        handle: '@karen',
    },
    message: 'Свет мой зеркальце скажи, да всю правду доложи! Кто на свете всех милее, всех румяней и светлее? Ответь в формате таблицы с рейтингом всех учатсников и укажи credit score с точностью до единицы.',
    info: {
        tokens: 2100,
    },
}, {
    uuid: '2',
    user: {
        imageUrl: avatar,
        name: 'Karen Charykov',
        handle: '@karen',
    },
    message: 'Свет мой зеркальце скажи, да всю правду доложи! Кто на свете всех милее, всех румяней и светлее? Ответь в формате таблицы с рейтингом всех учатсников и укажи credit score с точностью до единицы.',
    info: {
        tokens: 2100,
    },
}, {
    uuid: '3',
    user: {
        imageUrl: avatar,
        name: 'Karen Charykov',
        handle: '@karen',
    },
    message: 'Свет мой зеркальце скажи, да всю правду доложи! Кто на свете всех милее, всех румяней и светлее? Ответь в формате таблицы с рейтингом всех учатсников и укажи credit score с точностью до единицы.',
    info: {
        tokens: 2100,
    },
}, {
    uuid: '4',
    user: {
        imageUrl: avatar,
        name: 'Karen Charykov',
        handle: '@karen',
    },
    message: 'Свет мой зеркальце скажи, да всю правду доложи! Кто на свете всех милее, всех румяней и светлее? Ответь в формате таблицы с рейтингом всех учатсников и укажи credit score с точностью до единицы.',
    info: {
        tokens: 2100,
    },
}]