import { Path, Svg } from "./styles"
import { IconProps } from "./types";

const path = [
    'M0.5 5.05V3.425C0.5 1.8065 1.8065 0.5 3.425 0.5H5.05',
    'M8.95002 0.5H10.575C12.1935 0.5 13.5 1.8065 13.5 3.425V5.05',
    'M13.5 9.60004V10.575C13.5 12.1935 12.1935 13.5 10.575 13.5H9.60001',
    'M5.05 13.5H3.425C1.8065 13.5 0.5 12.1935 0.5 10.575V8.95001',
    [
        'M4.72501 6.35004C5.62247 6.35004 6.35001 5.6225 6.35001 4.72504C6.35001 3.82757',
        '5.62247 3.10004 4.72501 3.10004C3.82755 3.10004 3.10001 3.82757 3.10001 4.72504C3.10001',
        '5.6225 3.82755 6.35004 4.72501 6.35004Z',
    ].join(' '),
    [
        'M4.07501 10.9C4.61349 10.9 5.05001 10.4635 5.05001 9.92501C5.05001 9.38653 4.61349',
        '8.95001 4.07501 8.95001C3.53653 8.95001 3.10001 9.38653 3.10001 9.92501C3.10001',
        '10.4635 3.53653 10.9 4.07501 10.9Z',
    ].join(' '),
    [
        'M9.92502 5.05004C10.4635 5.05004 10.9 4.61351 10.9 4.07504C10.9 3.53656 10.4635',
        '3.10004 9.92502 3.10004C9.38654 3.10004 8.95002 3.53656 8.95002 4.07504C8.95002 4.61351',
        '9.38654 5.05004 9.92502 5.05004Z'
    ].join(' '),
    [
        'M9.27499 10.9C10.1725 10.9 10.9 10.1724 10.9 9.27496C10.9 8.3775 10.1725 7.64996 9.27499',
        '7.64996C8.37753 7.64996 7.64999 8.3775 7.64999 9.27496C7.64999 10.1724 8.37753 10.9 9.27499 10.9Z',
    ].join(' '),
];

export const IconWorkspace = ({
    width = 15,
    height = 14,
}: IconProps) => (
    <Svg width={width} height={height} viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
        <Path d={path[0]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[1]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[2]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[3]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[4]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[5]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[6]} strokeLinejoin="round" strokeLinecap="round" />
        <Path d={path[7]} strokeLinejoin="round" strokeLinecap="round" />
    </Svg>
)