import { AppWorkspace } from "../../../../../types/chat"
import { WorkspaceSection } from "./workspace"
import wsAvatarUrl from '../../../../../assets/mocks/wsavatar.png'
import { useNavigate } from "react-router"
import { routePath } from "../../../../../router/path"

const workspaces: AppWorkspace[] = [{
    id: '1',
    name: 'Default workspace',
    iconUrl: wsAvatarUrl,
}]

export const WorkspaceSectionContainer = () => {
    const navigate = useNavigate();
    const navigateToWorkspace = (id: string) => {
        navigate(routePath.workspaceById(id));
    };

    return <WorkspaceSection workspaces={workspaces} navigateToWorkspace={navigateToWorkspace} />
}