import styled from "@emotion/styled";

export const Container = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'stretch',
    padding: 15,
    gap: 20,
    boxSizing: 'border-box' as const,
    height: '100%',
    width: '100%',
}));

export const Content = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 20,
}))

export const Title = styled.h2((props) => ({
    color: props.theme.colors.text.primary,
}))

export const TableHead = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 30,
}));

export const Label = styled.span((props) => ({
    fontSize: 12,
    lineHeight: 1.1,
    color: props.theme.colors.text.primary,
    opacity: 0.5,
}));
