import styled from '@emotion/styled';
import { Button } from '../../../../../components/buttons';

export const Workspaces = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
}));

export const WorkspaceButton = styled(Button)(() => ({

}));

export const WorkspaceIcon = styled.img(() => ({

}));

export const WorkspaceButtonText = styled.span(() => ({

}));