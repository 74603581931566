import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../../api"
import { rpcMethods } from "../../../api/endpoints";
import { decorateDialogRecord } from "./utils";
import { RawDialogRecord } from "./types";

export interface FetchDialogsResponse {
    chats: RawDialogRecord[];
}

interface FetchDialogsArgs {
}

export const action = 'chat/list'

export const fetchDialogs = createAsyncThunk(
    action,
    async (args: FetchDialogsArgs, thunkApi) => {
        const response = await callApi<FetchDialogsResponse>(rpcMethods.chat.list, {});
        return response.chats.map(decorateDialogRecord);
    },
)