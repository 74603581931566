export const routes = {
    root: '/',
    recent: 'recent',
    chat: {
        root: '/chat',
        byId: '/chat/:id',
    },
    workspaces: {
        root: '/workspaces',
        byId: '/workspaces/:id',
    },
    spaces: {
        root: '/spaces',
        byId: '/spaces/:id',
    },
    profile: '/profile',
    settings: {
        root: '/settings',
        chat: '/settings/chat',
        integrations: '/settings/integrations',
    },
};

export const routePath = {
    ...routes,
    chatById: (uuid: string) => [routes.chat.root, uuid].join('/'),
    workspaceById: (uuid: string) => [routes.workspaces.root, uuid].join('/'),
    spaceById: (uuid: string) => [routes.spaces.root, uuid].join('/'),
}