import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/store";
import { IntegrationSettings } from "./integrations";
import { Integration } from "types/app";
import { selectIntegration, deselectIntegration, selectIntegrations, selectSelectedIntegration } from "state/slices/integrations";
import { IntegrationModal } from "./integrationModal";
import { useState } from "react";
import { addIntegration } from "state/thunks/integrations/addIntegration";
import { useStatedAutoRequest } from "api/hooks/useStatedRequest";
import { fetchIntegrationList } from "state/thunks/integrations/fetchIntegrationList";
import { toggleIntegration as toggleIntegrationThunk } from "state/thunks/integrations/toggleIntegration";
import { removeIntegration as removeIntegrationThunk } from "state/thunks/integrations/removeIntegration";

export const IntegrationSettingsContainer = () => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const integrations = useSelector(selectIntegrations);
    const selectedIntegration = useSelector(selectSelectedIntegration);
    useStatedAutoRequest(fetchIntegrationList, {});
    const dispatch = useAppDispatch();

    const onSelectIntegration = (integration: Integration) => {
        dispatch(selectIntegration(integration));
    };

    const onClearIntegration = () => {
        dispatch(deselectIntegration());
    }

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        onClearIntegration();
        setModalOpen(false);
    }

    const createNewIntegration = (item: Pick<Integration, 'type' | 'config'>) => {
        dispatch(addIntegration({
            type: item.type,
            config: item.config,
        }));
    };

    const viewIntegration = (id: string) => () => {
        const integration = integrations.find(item => item.id === id);
        if (!integration) {
            return;
        }

        onSelectIntegration(integration);
        openModal();
    };

    const toggleIntegration = (id: string) => (): any => {
        const integration = integrations.find(item => item.id === id);
        if (!integration) {
            return null;
        }

        return dispatch(toggleIntegrationThunk({
            id,
        }));
    }

    const removeIntegration = (id: string) => () => {
        const integration = integrations.find(item => item.id === id);
        if (!integration) {
            return;
        }

        dispatch(removeIntegrationThunk(integration));
    };

    return (
        <>
            <IntegrationModal
                selectedIntegration={selectedIntegration}
                onAddIntegration={createNewIntegration}
                onClose={closeModal}
                open={isModalOpen}
                isLoading={false}
            />
            <IntegrationSettings
                onAdd={openModal}
                integrations={integrations}
                viewIntegration={viewIntegration}
                removeIntegration={removeIntegration}
                toggleIntegration={toggleIntegration}
            />
        </>
    );
}

export default IntegrationSettingsContainer;