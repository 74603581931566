import styled from '@emotion/styled';

export const Section = styled.div(() => ({
    padding: 18,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderBottom: `1px solid rgba(217, 217, 217, 0.10)`,
}));

export const SectionTitle = styled.h5((props) => ({
    color: props.theme.colors.text.primary,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.1,
    opacity: 0.5,
    margin: 0,
    marginBottom: 18,
}));

export const Workspaces = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 8,
}));
