import { useTranslation } from "react-i18next";
import { ModelPrompt } from "../../../types/app";
import { Container, Prompts, Title } from "./styles";
import { LABELS } from "./keys";
import { Button } from "../../../components/buttons";

interface PreselectedPromptsProps {
    prompts: ModelPrompt[];
}

export const PreselectedPrompts = ({ prompts }: PreselectedPromptsProps) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Title>
                {t(LABELS.TITLE)}
            </Title>
            <Prompts>
                {prompts.map(prompt => <Button key={prompt.id} primary>{prompt.name}</Button>)}
            </Prompts>
        </Container>
    );
}