import { ChatMessage } from "../../../types/chat";
import { RawDialogRecord, RawMessage } from "./types";
import avatar from '../../../assets/mocks/wsavatar.png';
import { DialogRecord } from "../../../types/app";

export const decorateMessage = (rawMessage: RawMessage): ChatMessage => ({
    uuid: rawMessage.uuid,
    user: rawMessage.role === 'assistant'
    ? {
        imageUrl: avatar,
        name: rawMessage.model,
        handle: '',
    }
    : {
        imageUrl: avatar,
        name: 'Test user',
        handle: '',
    },
    message: rawMessage.content,
    info: {
        tokens: 0,
    }
})

export const decorateDialogRecord = (dialog: RawDialogRecord): DialogRecord => ({
    uuid: dialog.uuid,
    title: dialog.title,
    lastMessageId: dialog.last_message_id,
})