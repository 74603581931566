import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../state/slices/user";
import { addMessage, selectEnabledCapsules, selectModels, selectSelectedModel } from "../../state/slices/chat";
import { useAppDispatch } from "../../state/store";
import { ChatMessage } from "../../types/chat";
import { sendMessage as sendMessageThunk } from "../../state/thunks/chat/sendMessage";

export const useSendMessage = () => {
    const enabledCapsules = useSelector(selectEnabledCapsules);
    const currentUser = useSelector(selectCurrentUser);
    const selectedModel = useSelector(selectSelectedModel);
    const dispatch = useAppDispatch();

    const sendMessage = async (message: string, chatId: string) => {
        const canSendMessage = [currentUser, chatId, selectedModel, message].every(Boolean);
        if (!canSendMessage) {
            return false;
        }

        const chatMessage: ChatMessage = {
            uuid: Math.random().toString(),
            user: currentUser!,
            message,
            info: {
                tokens: Number((Math.random() * 4096).toFixed(0)),
            }
        };

        dispatch(addMessage(chatMessage));
        await dispatch(sendMessageThunk({
            chatId: chatId!,
            message,
            model: selectedModel!,
            settings: {
                capsuleIds: enabledCapsules,
            },
        }));
        return true;
    }

    return sendMessage;
}