import { LoginPage } from "./login";
import { login as loginAction } from '../../state/thunks/auth/login';
import { useStatedRequest } from "../../api/hooks/useStatedRequest";
import { useState } from "react";

export const LoginContainer = () => {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { invoke, isLoading } = useStatedRequest(loginAction);

    const handleLogin = () => {
        invoke({ login, password });
    }

    return <LoginPage
        onLogin={handleLogin}
        login={login}
        isLoading={isLoading}
        password={password}
        setLogin={setLogin}
        setPassword={setPassword}
    />;
};

export default LoginContainer;