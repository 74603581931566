import { IntegrationType } from "types/app";
import { makeLabels } from "utils/labels";

const prefix = 'pages.settings.integrations.integrationModal';
export const LABELS = makeLabels(prefix, {
    CLOSE_BUTTON_TITLE: 'closeButtonTitle',
    TITLE: 'title',
    VIEW_TITLE: 'viewTitle',
    EDIT_TITLE: 'editTitle',
    NAME_LABEL: 'nameLabel',
    NAME_PLACEHOLDER: 'namePlaceholder',
    TOKEN_LABEL: 'tokenLabel',
    TOKEN_PLACEHOLDER: 'tokenPlaceholder',
    DESCRIPTION_LABEL: 'descriptionLabel',
    DESCRIPTION_PLACEHOLDER: 'descriptionPlaceholder',
    LIMIT_LABEL: 'limitLabel',
    SUBMIT_LABEL: 'submitLabel',
    UPDATE_LABEL: 'updateLabel',
    CHANGE_NAME_LABEL: 'changeName',
    REMOVE_BUTTON_LABEL: 'removeButtonLabel',
    CANCEL_BUTTON_LABEL: 'cancelButtonLabel',
    CONFIG_LABEL: 'configLabel',
    KEY_PLACEHOLDER: 'keyPlaceholder',
    VALUE_PLACEHOLDER: 'valuePlaceholder',
    ADD_CONFIG_ROW: 'addConfigRow',
});

export const defaultSlackConfig: Record<string, string> = {
    SLACK_BOT_TOKEN: '',
    SLACK_APP_TOKEN: '',
    SLACK_SIGNING_SECRET: '',
    SLACK_CLIENT_ID: '',
    SLACK_CLIENT_SECRET: '',
};

export const defaultTeamsConfig: Record<string, string> = {
    TEAMS_BOT_TOKEN: '',
    TEAMS_APP_ID: '',
    TEAMS_TENANT_ID: '',
};

export const defaultTelegramConfig: Record<string, string> = {
    TELEGRAM_BOT_TOKEN: '',
    TELEGRAM_WEBHOOK_URL: '',
};

export const defaultConfigs: Record<IntegrationType, Record<string, string>> = {
    [IntegrationType.Teams]: defaultTeamsConfig,
    [IntegrationType.Slack]: defaultSlackConfig,
    [IntegrationType.Telegram]: defaultTelegramConfig,
}